import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useSnapshot } from "valtio";
import SaveIcon from "@mui/icons-material/Save";
import { v4 as uuidv4 } from "uuid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { state } from "./Store";
import getLayouts from "./getLayouts";
import getModuleFields from "./getModuleFields";
import AppButton from "./Atoms/AppButton";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SaveAndLoad({
  handleSave,
  existingData,
  handleExpanded,
  addModule = true,
}) {
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  //load and save data
  const [fetchDataFromDb, setFetchDataFromDb] = useState(false);
  const [saveDataToDb, setSaveDataToDb] = useState(false);

  const stateSnap = useSnapshot(state);
  console.log(
    "🚀 ~ file: SaveAndLoad.js ~ line 13 ~ SaveAndLoad ~ existingData",
    stateSnap
  );
  const loadDataFromDb = async (data) => {
    try {
      //set settingName, settingDescription,connectionName
      state.fromModuleData.settingName = data.settingName;
      state.fromModuleData.settingDescription = data.settingDescription;
      state.fromModuleData.connectionName = data.connectionName;

      //fields of all modules will be stored in the allModules
      const allModules = {};
      //fields of all subform modules will be stored in allSubformModules
      const allSubformModules = {};
      //layout info of all modules will be stored in layoutData
      const layoutData = {};

      //subform modules of all modules layout
      const subformModulesLayouts = {};

      allModules[data.fromModuleName] = { fields: [] };

      //grab all the module names from data.containers and populate them to allModules and allSubformModules
      data.containers.forEach((container) => {
        allModules[container.toModuleName] = {
          fields: [],
        };

        if (container.subforms?.length > 0) {
          container.subforms.forEach((subform) => {
            if (subform.fromModuleName) {
              allSubformModules[subform.fromModuleName] = {
                fields: [],
              };
            }
            if (subform.toModuleName) {
              allSubformModules[subform.toModuleName] = {
                fields: [],
              };
            }
          });
        }
      });

      const allModuleNames = Object.keys(allModules);
      const allSubformModuleNames = Object.keys(allSubformModules);
      console.log({
        allModuleNames,
        allSubformModuleNames,
      });

      //populate respective modules layout data to layoutData
      for (let i = 0; i < allModuleNames.length; i++) {
        const moduleName = allModuleNames[i];
        let { allLayouts, status, message, subformModulesOfLayouts } =
          await getLayouts(moduleName);

        if (status === "error") {
          alert(message);
          break;
        }
        layoutData[moduleName] = allLayouts;
        subformModulesLayouts[moduleName] = subformModulesOfLayouts;
      }

      //populate respective subform modules fields to allSubformModules
      for (let i = 0; i < allSubformModuleNames.length; i++) {
        const moduleName = allSubformModuleNames[i];
        const { ok, message, fields } = await getModuleFields(moduleName);
        if (!ok) {
          console.log({ message });
          alert(message);
        }
        allSubformModules[moduleName].fields = fields;
      }

      console.log({
        layoutData,
        allSubformModules,
        subformModulesLayouts,
      });

      //this part will handle fromModuleData
      const fromModuleData = {};
      {
        //-----------------target--------------
        // fromModuleData: {
        //   fromModuleName: null,
        //   fields: [],
        //   subformModules: [],
        //   allowedTypes: { text: true, textarea: true },
        //   error: null,
        //   loading: false,
        // },

        const selectedLayout =
          layoutData[data.fromModuleName][data.selectedFromModuleLayout.id];

        const fields =
          layoutData[data.fromModuleName][selectedLayout.id].fields;

        // get all lookup modules from fromModuleFields
        const lookupModules = fields.flatMap((field) =>
          field.data_type === "lookup" ? [field] : []
        );
        console.log({ lookupModules });

        // it contains all fields include lookupmodule fields (for fromModuleFields)
        let allfields_include_lookupmodule_fields = [];

        // fields from main module and push those fields to allfields_include_lookupmodule_fields
        for (let field = 0; field < fields.length; field++) {
          allfields_include_lookupmodule_fields.push({
            ...fields[field],
            moduleName: data.fromModuleName,
          });
        }

        //grab all lookup module fields and push those fields to allfields_include_lookupmodule_fields
        for (
          let lookupModule = 0;
          lookupModule < lookupModules.length;
          lookupModule++
        ) {
          let getLookupFields = await getModuleFields(
            lookupModules[lookupModule].lookup.module.api_name
          );

          getLookupFields.fields.forEach((lookupField) => {
            allfields_include_lookupmodule_fields.push({
              ...lookupField,
              moduleName: lookupModules[lookupModule].field_label,
              lookupfield_api_name: lookupModules[lookupModule].api_name,
            });
          });
        }

        fromModuleData.fromModuleName = data.fromModuleName;
        fromModuleData.fields = allfields_include_lookupmodule_fields;

        fromModuleData.subformModules =
          subformModulesLayouts[data.fromModuleName][selectedLayout.id];
        fromModuleData.selectedLayout = data.selectedFromModuleLayout;

        fromModuleData.subformModulesOfLayouts =
          subformModulesLayouts[data.fromModuleName];
        fromModuleData.allowedTypes = {
          text: true,
          textarea: true,
        };
        fromModuleData.error = null;
        fromModuleData.loading = false;

        console.log({ fromModuleData });
        //set settingName, settingDescription,connectionName
        fromModuleData.settingName = data.settingName;
        fromModuleData.settingDescription = data.settingDescription;
        fromModuleData.connectionName = data.connectionName;

        state.fromModuleData = fromModuleData;
      }

      //this part will handle containers
      {
        const totalContainers = Object.keys(data.containers).length;
        //get layouts data of toModule
        for (let i = 0; i < totalContainers; i++) {
          const toModuleName = data.containers[i].toModuleName;
          let { selectedLayout, allLayouts, status, message } =
            await getLayouts(toModuleName);

          layoutData[toModuleName] = allLayouts;
          if (status === "error") {
            // alert(message);
            break;
          }
        }

        console.log({
          layoutData,
          allSubformModules,
          subformModulesLayouts,
        });

        const containers = {};

        data.containers.forEach((container, index) => {
          const containerData = {};
          const subformData = {};
          const toModuleName = container.toModuleName;
          const fromModuleName = data.fromModuleName;
          const selectedLayoutId = container.selectedLayout.id;

          containerData.toModuleName = container.toModuleName;
          containerData.error = null;
          containerData.toModuleFields =
            layoutData[toModuleName][selectedLayoutId].fields;
          containerData.fieldMapping = container.fieldMapping;
          containerData.layoutData = layoutData[toModuleName];
          containerData.selectedLayout = container.selectedLayout;
          containerData.shouldSubformAdd = container.shouldSubformAdd;
          containerData.subformModules = {
            to: subformModulesLayouts[toModuleName][selectedLayoutId],
            from: subformModulesLayouts[fromModuleName][
              data.selectedFromModuleLayout.id
            ],
          };
          containerData.subformModulesOfLayouts =
            subformModulesLayouts[toModuleName];
          containerData.allowedTypes = {
            text: true,
            textarea: true,
          };
          containerData.loading = false;

          container.subforms?.forEach((subform) => {
            if (subform.fromModuleName && subform.toModuleName) {
              const data = {};
              const subformId = subform.subformId;
              const fromModuleName = subform.fromModuleName;
              const toModuleName = subform.toModuleName;
              const fromModuleFields = allSubformModules[fromModuleName].fields;
              const toModuleFields = allSubformModules[toModuleName].fields;
              const fieldMapping = subform.fieldMapping;
              data.fromModuleData = {
                fromModuleName: fromModuleName,
                fields: fromModuleFields,
                error: null,
                allowedTypes: {
                  text: true,
                  textarea: true,
                },
                loading: false,
              };
              data.toModuleData = {
                toModuleName: toModuleName,
                error: null,
                toModuleFields: toModuleFields,
                loading: false,
              };
              data.subformId = subformId;
              data.fieldMapping = fieldMapping;
              subformData[subformId] = data;
            }
          });
          containerData.settingName = container.settingName;
          containerData.settings = container.settings;
          containerData.subforms = {
            containers: subformData,
          };

          containers[index] = containerData;
        });
        console.log({ fromModuleData, containers });

        state.containers = containers;
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {/* Show Error */}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      {/* Add Module */}

      {stateSnap.fromModuleData.fromModuleName && addModule && (
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <AppButton
            variant="contained"
            size="meidum"
            sx={{ borderRadius: "25px", ml: 1.5 }}
            onClick={() => {
              //add container
              const containerId = uuidv4();
              state.addContainer({ containerId });
              handleExpanded(containerId);
            }}
          >
            Add Module
          </AppButton>
        </Box>
      )}
      <Box>
        <LoadingButton
          loading={saveDataToDb}
          loadingPosition="start"
          variant="outlined"
          sx={{ borderRadius: "30px" }}
          color="primary"
          size="medium"
          startIcon={<SaveIcon />}
          disabled={Object.keys(stateSnap.containers).length === 0 && true}
          onClick={async () => {
            setSaveDataToDb(true);

            //this is what we save into db
            const info = {
              fromModuleName: state.fromModuleData.fromModuleName,
              selectedFromModuleLayout: {
                id: stateSnap.fromModuleData.selectedLayout.id,
                name: stateSnap.fromModuleData.selectedLayout.name,
              },
              settingName: state.fromModuleData.settingName,
              settingDescription: state.fromModuleData.settingDescription,
              deleteRecord: state.fromModuleData.deleteRecord,
              connectionName: JSON.parse(state.fromModuleData.connectionName),
              containers: [],
              service_logo: existingData?.service_logo,
            };
            let error;
            error = null;
            // try {
            Object.values(state.containers).forEach((container) => {
              const data = {};
              if (container.toModuleName) {
                const selectedLayout = {
                  field_label: container.selectedLayout.field_label,
                  id: container.selectedLayout.id,
                };
                data.toModuleName = container.toModuleName;
                data.shouldSubformAdd = container.shouldSubformAdd;
                data.selectedLayout = { ...selectedLayout };
              } else {
                console.log("ERROR");
                error = "Please Select Module";
              }

              container.fieldMapping.forEach((field) => {
                const fieldValue = field.from || field?.defaultValue?.value;
                if (field.mandatory && !fieldValue) {
                  console.log({ field });
                  error = "Mandatory field can't be empty";
                }
              });

              data.fieldMapping = container.fieldMapping;

              if (!error) {
                Object.values(container.subforms.containers).forEach(
                  (subform) => {
                    if (
                      !subform.toModuleData.toModuleName ||
                      !subform.fromModuleData.fromModuleName
                    ) {
                      error =
                        "Subform To OR From module can't be empty.Please select subform module.";
                      return;
                    }
                    subform.fieldMapping.forEach((field) => {
                      if (field.mandatory && !field.from) {
                        error = "Subform mandatory field can't be empty";
                      }
                    });
                  }
                );

                const subforms = Object.values(
                  container.subforms.containers
                ).map((subform) => ({
                  fromModuleName: subform.fromModuleData.fromModuleName,
                  toModuleName: subform.toModuleData.toModuleName,
                  fieldMapping: subform.fieldMapping,
                  subformId: subform.subformId,
                }));
                data.subforms = subforms;
                data.settingName = container.settingName;
                data.settings = container.settings;
                info.containers.push(data);
              }
            });
            console.log({ info });
            console.log({ error });
            if (error) {
              // alert(error);
              setOpen(true);
              setErrorMsg(error);
              setSaveDataToDb(false);
              return;
            }

            await handleSave(info);
            state.reset();
            // } catch (err) {
            //   console.log({ err });
            //   alert(err);
            // }

            setSaveDataToDb(false);
          }}
        >
          Save To DB
        </LoadingButton>
      </Box>
    </Box>
  );
}
