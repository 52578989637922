import CableIcon from "@mui/icons-material/Cable";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
const menuItems = [
  {
    label: "Settings",
    icon: <SettingsIcon />,
    href: "settings",
  },
  {
    label: "Connections",
    icon: <CableIcon />,
    href: "connections",
  },
  {
    label: "Analytics",
    icon: <SignalCellularAltIcon />,
    href: "analytics",
  },
  {
    label: "Request a feature",
    icon: <FeaturedPlayListOutlinedIcon />,
    href: "request-feature",
  },
  {
    label: "Contact Support",
    icon: <ContactSupportIcon />,
    href: "contact-support",
  },
  {
    label: "Profile",
    icon: <PersonIcon />,
    href: "profile",
  },
];

const state = proxy({
  darkMode: false,
  color: "BLUE",
  mobileOpen: false,
  activePage: "services",
  activeSetting: {},
  orgId: "",
  apiKey: "",
  accTokens: {},
  menuItems: menuItems,
  zuid: "708201665",
  loading: false,
  loadingMessage: "",
  currentUserDetails: {},
  currentUserEmail: "",
  zapiKey: "",
  apiDomain: "",
  planCode: "",
  screenSize: "",
  moduleApiName: "",
});
const unsub = devtools(state, "Admin Store");
export { state };
