// import { joiResolver } from "@hookform/resolvers/joi";
import axios from "axios";
// import Joi from "joi";
const ZOHO = window.ZOHO;
export const iwebSetNestedObjectValue = (obj, path, val) => {
  const keys = path.split("__");
  const lastKey = keys.pop();
  const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
  lastObj[lastKey] = val;
  return obj;
};

export const iwebGetNestedObjectValue = (obj, path) => {
  const keys = path.split("__");
  const lastKey = keys.pop();
  const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
  return lastObj[lastKey];
};
export const iwebConvertKeyToObj = (inputValues) => {
  let returnedObj = {};
  Object.keys(inputValues).forEach((path) => {
    const value = inputValues[path];
    iwebSetNestedObjectValue(returnedObj, path, value);
  });
  return returnedObj;
};

export const flatten = function (object) {
  var tempA = {};
  for (let i in object) {
    if (typeof object[i] == "object" && object[i].inputs !== undefined) {
      let key = object[i].id;
      console.log("Key ", key, object[i]);
      var tempB = flatten(object[i].inputs);
      for (let j in tempB) {
        tempA[key + "__" + j] = tempB[j];
      }
    } else if (object[i].validation !== undefined) {
      tempA[object[i].id] = object[i].validation;
    }
  }
  return tempA;
};

export const normalFlatten = function (object) {
  var tempA = {};
  for (let i in object) {
    if (typeof object[i] === "object" && object[i] !== null) {
      var tempB = normalFlatten(object[i]);
      for (let j in tempB) {
        tempA[i + "__" + j] = tempB[j];
      }
    } else {
      tempA[i] = object[i];
    }
  }
  return tempA;
};
export const flattenObj = (ob, seperator = ".") => {
  // The object which contains the
  // final result
  let result = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === "object") {
      const temp = flattenObj(ob[i], seperator);
      for (const j in temp) {
        // Store temp in result
        result[i + seperator + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};

export const unFlattenObj = (data, seperator = ".") => {
  let result = {};
  for (let i in data) {
    let keys = i.split(seperator);
    keys.reduce((acc, value, index) => {
      return (
        acc[value] ||
        (acc[value] = isNaN(Number(keys[index + 1]))
          ? keys.length - 1 === index
            ? data[i]
            : {}
          : [])
      );
    }, result);
  }
  return result;
};
export const reGenerateJSON = (proxyJson) => {
  const proxyJsonStr = JSON.stringify(proxyJson);
  const reGeneratedJSON = JSON.parse(proxyJsonStr);
  return reGeneratedJSON;
};
export const prepareDragListData = (fieldList, schemaList) => {
  let schemaFieldNames = [];
  let returnList = [];
  /**
   * Mapping Existing Fields and its Modified Data
   */
  reGenerateJSON(schemaList).forEach((fieldMap) => {
    schemaFieldNames.push(fieldMap.id);
    returnList.push(fieldMap);
  });
  /**
   * Prepare the List based on Current Data and Remaining List
   */
  reGenerateJSON(fieldList).forEach((fieldMap) => {
    if (schemaFieldNames.includes(fieldMap.id) === false) {
      returnList.push(fieldMap);
    }
  });
  return returnList;
};

export const deDuplicateOptions = (options, filteredBy = "id") => {
  let sortedOptions = {};
  options.forEach((option) => {
    sortedOptions[option[filteredBy]] = option;
  });
  return Object.values(sortedOptions);
};
export const sortOptions = (options, sortBy, sortOrder = "asc") => {
  return options.sort((a, b) => {
    const aVal = a[sortBy] ? a[sortBy].toLowerCase() : "";
    const bVal = b[sortBy] ? b[sortBy].toLowerCase() : "";
    if (sortOrder === "asc") {
      return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    } else {
      return aVal > bVal ? -1 : aVal < bVal ? 1 : 0;
    }
  });
  // return Object.values(sortedOptions);
};

export const queryGenerator = (
  conditions,
  operatorType = "and",
  formValues = {}
) => {
  const returnedStr = conditions.reduce(
    (accumulator, currentValue, index, array) => {
      let conditionString = "";
      if (currentValue.type === undefined) {
        if (currentValue.value !== undefined) {
          if (currentValue.operator === "equals") {
            conditionString = `${currentValue.fieldApiName}:equals:${currentValue.value}`;
          }
          if (currentValue.operator === "starts_with") {
            conditionString = `${currentValue.fieldApiName}:starts_with:${currentValue.value}`;
          }
          if (currentValue.operator === "contains") {
            conditionString = `${currentValue.fieldApiName}:starts_with:*${currentValue.value}*`;
          }
        }
        //
        if (
          currentValue.formFieldId !== undefined &&
          formValues[currentValue.formFieldId] !== undefined &&
          formValues[currentValue.formFieldId] !== ""
        ) {
          const formValue = formValues[currentValue.formFieldId];
          const queryValue = formValue?.id ? formValue.id : formValue;
          if (currentValue.operator === "equals") {
            conditionString = `${currentValue.fieldApiName}:equals:${queryValue}`;
          }
          if (currentValue.operator === "starts_with") {
            conditionString = `${currentValue.fieldApiName}:starts_with:${queryValue}`;
          }
          if (currentValue.operator === "contains") {
            conditionString = `${currentValue.fieldApiName}:starts_with:*${queryValue}*`;
          }
        }

        //
        if (accumulator === "") {
          return `(${conditionString})`;
        } else {
          return `(${accumulator} ${operatorType} (${conditionString}))`;
        }
      } else {
        const queryString = queryGenerator(
          currentValue.rules,
          currentValue.type,
          formValues
        );
        if (accumulator === "") {
          return queryString;
        } else {
          return `(${accumulator} ${operatorType} ${queryString})`;
        }
      }
    },
    ""
  );
  return returnedStr;
};

/**
 * Generate Option Label based on Field Selected to
 * search on all data displayed in the Lable
 * Option  The Value
 */
export const getOptionLabel = (option, optionLabels) => {
  if (optionLabels !== undefined) {
    const optionLabel = optionLabels.reduce((accumulator, currentValue) => {
      if (
        option[currentValue] !== undefined &&
        option[currentValue] !== "" &&
        option[currentValue] !== null
      ) {
        if (accumulator === "") {
          return option[currentValue];
        } else {
          return accumulator + " - " + option[currentValue];
        }
      } else {
        return accumulator;
      }
    }, "");
    return optionLabel;
  } else {
    return "";
  }
};

export const processObjectList = (datas, filterObject) => {
  const fields =
    filterObject.fields !== undefined ? filterObject.fields : undefined;
  const skipFields =
    filterObject.skipFields !== undefined ? filterObject.skipFields : undefined;
  const dataMap =
    filterObject.dataMap !== undefined ? filterObject.dataMap : undefined;
  let returnList = [];
  /**
   * Show Only Fields Data
   */

  datas.forEach((data) => {
    let returnObject = {};
    // For Data Map
    if (dataMap !== undefined && Object.keys(dataMap).length > 0) {
      Object.keys(dataMap).forEach((key) => {
        const apiName = dataMap[key];
        if (!key.includes("default|||")) {
          returnObject[key] = data[apiName];
        } else {
          returnObject[key.replace("default|||", "")] = apiName;
        }
      });
    } else {
      Object.keys(data).forEach((fieldApiName) => {
        let validField = true;
        // For Fields to FETCH based on API Fields List
        if (
          validField &&
          fields?.length > 0 &&
          !fields.includes(fieldApiName)
        ) {
          validField = false;
        }

        // For Fields to SKIP based on API Fields List
        if (
          validField &&
          skipFields !== undefined &&
          skipFields?.length > 0 &&
          skipFields?.includes(fieldApiName)
        ) {
          validField = false;
        }

        if (validField) {
          returnObject[fieldApiName] = data[fieldApiName];
        }
      });
    }
    returnList.push(returnObject);
  });
  return returnList;
};

export const isIncludes = (values, value) => {
  return values.some((indv) => JSON.stringify(indv) === JSON.stringify(value));
};

//? For Processing a List of Fields,
//? Datas = List of ZOHO Field Maps received from API Request
//todo filterObject = NOT REQUIRED NOW, need to delete
export const processZohoFields = (datas, fieldSchemas) => {
  let dataMap = undefined;
  let returnList = [];
  /**
   * Show Only Fields Data
   */
  datas.forEach((data) => {
    const processedObject = processZohoField(data, fieldSchemas);
    if (Object.keys(processedObject).length > 0) {
      returnList.push(processedObject);
    }
  });
  return returnList;
};

//? For Processing a List of Fields,
//? Datas = List of ZOHO Field Maps received from API Request
//todo filterObject = NOT REQUIRED NOW, need to delete
export const processZohoFieldsV2 = (moduleDatas, baseModule, fieldSchemas) => {
  let dataMap = undefined;
  let returnList = [];
  /**
   * Show Only Fields Data
   */
  moduleDatas?.fieldNames[baseModule]?.forEach((crmFieldMap) => {
    const processedObject = processZohoField(
      crmFieldMap,
      fieldSchemas,
      moduleDatas
    );

    if (Object.keys(processedObject).length > 0) {
      returnList.push(processedObject);
    }
  });
  return returnList;
};

//? data is CRM Field Map from ZOHO
//? fieldSchema is default Field Schema for each Type
export const processZohoField = (crmFieldMap, fieldSchemas, moduleDatas) => {
  let dataMap = undefined;
  /**
   * Show Only Fields Data
   */
  let returnObject = {};
  const dataType = crmFieldMap.data_type;
  let fieldSchema = {};
  switch (dataType) {
    case "email":
      dataMap = {
        id: "id",
        name: "api_name",
        label: "field_label",
        "default|||grid": { sm: 6, md: 6, lg: 3, xl: 3 },
        rules: { required: "system_mandatory" },
      };
      fieldSchema = fieldSchemas.textfield;
      break;
    case "text":
    case "integer":
    case "double":
    case "currency":
    case "phone":
    case "website":
    case "bigint":
      dataMap = {
        id: "id",
        name: "api_name",
        label: "field_label",
        "default|||grid": { sm: 6, md: 6, lg: 3, xl: 3 },
        rules: { required: "system_mandatory" },
      };
      fieldSchema = fieldSchemas.textfield;
      break;
    case "boolean":
      dataMap = {
        id: "id",
        name: "api_name",
        label: "field_label",
        "default|||grid": { sm: 6, md: 6, lg: 3, xl: 3 },
      };
      fieldSchema = fieldSchemas.checkbox;
      break;
    case "date":
    case "datetime":
      dataMap = {
        id: "id",
        name: "api_name",
        label: "field_label",
        "default|||grid": { sm: 6, md: 6, lg: 3, xl: 3 },
      };
      fieldSchema = fieldSchemas.datetimepicker;
      break;
    case "picklist":
      dataMap = {
        id: "id",
        name: "api_name",
        label: "field_label",
        "default|||grid": { sm: 6, md: 6, lg: 3, xl: 3 },
        options: "pick_list_values",
        "default|||optionLabels": ["display_value"],
      };
      fieldSchema = fieldSchemas.autocomplete;
      break;
    case "lookup":
      if (crmFieldMap.api_name !== "Parent_Id") {
        dataMap = {
          id: "id",
          name: "api_name",
          label: "field_label",
          "default|||grid": { sm: 6, md: 6, lg: 3, xl: 3 },
          options: "pick_list_values",
          "default|||optionLabels": ["id"],
          dataSource: "lookup.module",
        };
        fieldSchema = fieldSchemas.autocomplete_zoho;
      }

      break;
    case "subform":
      const inputs = processZohoFieldsV2(
        moduleDatas,
        crmFieldMap.field_label,
        fieldSchemas
      );
      console.log(crmFieldMap.data_type, crmFieldMap.field_label);
      dataMap = {
        id: "id",
        name: "api_name",
        label: "field_label",
        "default|||inputs": inputs,
        // rules: { required: "system_mandatory" },
        "default|||grid": { sm: 12, md: 12, lg: 12, xl: 12 },
      };
      fieldSchema = fieldSchemas["list-table"];
      break;
    default:
      break;
  }

  if (Object.keys(fieldSchema).length > 0) {
    // For Data Map
    if (dataMap !== undefined && Object.keys(dataMap).length > 0) {
      Object.keys(dataMap).forEach((key) => {
        const apiName = dataMap[key];
        if (!key.includes("default|||")) {
          if (typeof apiName === "object") {
            let tempObject = {};
            Object.keys(apiName).forEach((innerFieldKey) => {
              const innerApiName = apiName[innerFieldKey];
              if (innerApiName.includes(".")) {
                tempObject[innerFieldKey] =
                  flattenObj(crmFieldMap)[innerApiName];
              } else {
                tempObject[innerFieldKey] = crmFieldMap[innerApiName];
              }
              returnObject[key] = tempObject;
            });
          } else if (apiName.includes(".")) {
            returnObject[key] = flattenObj(crmFieldMap)[apiName];
          } else {
            returnObject[key] = crmFieldMap[apiName];
          }
        } else {
          returnObject[key.replace("default|||", "")] = apiName;
        }
      });
    }
    return {
      ...fieldSchema,
      ...returnObject,
    };
  }
  return {};
};

/**
 * How to use it
  getModuleAndLookupDataAndFields(entity, entityId).then(
        (data) => {
          // Add your business logic
        }
      );
 */
export const getModuleAndLookupDataAndFields = async (entity, entityId) => {
  let returnData = { fieldNames: {}, recordData: {} };
  // Getting Record Data of Entity
  const getRecordData = await ZOHO.CRM.API.getRecord({
    Entity: entity,
    RecordID: entityId,
  });
  if (getRecordData !== undefined) {
    returnData.recordData[entity] = getRecordData.data[0];
  }

  /**
   * entity Field Names of Entity
   */
  const entityFields = await ZOHO.CRM.META.getFields({ Entity: entity });
  returnData.fieldNames[entity] = entityFields.fields;
  /**
   * Need to use async and await
   */
  return Promise.all(
    entityFields.fields.map(async (fieldMap) => {
      if (fieldMap.data_type === "lookup") {
        const lookupFields = await ZOHO.CRM.META.getFields({
          Entity: fieldMap.lookup.module.api_name,
        });
        if (lookupFields !== undefined) {
          returnData.fieldNames[fieldMap.field_label] = lookupFields.fields;
        }
        const lookupData = await ZOHO.CRM.API.getRecord({
          Entity: fieldMap.lookup.module.api_name,
          RecordID: returnData.recordData[entity][fieldMap.lookup.api_name],
        });
        if (lookupData !== undefined) {
          returnData.recordData[fieldMap.field_label] = lookupData.data[0];
        }
        /**
         * Return is necessary for Promise.all()
         */
        return lookupFields;
      } else if (fieldMap.data_type === "subform") {
        const lookupFields = await ZOHO.CRM.META.getFields({
          Entity: fieldMap.subform.module,
        });
        if (lookupFields !== undefined) {
          returnData.fieldNames[fieldMap.field_label] = lookupFields.fields;
        }
        // const lookupData = await ZOHO.CRM.API.getRecord({
        //     Entity: fieldMap.lookup.module.api_name,
        //     RecordID:
        //         returnData.recordData[entity][fieldMap.lookup.api_name],
        // });
        // if (lookupData !== undefined) {
        //     returnData.recordData[fieldMap.field_label] =
        //         lookupData.data[0];
        // }
        /**
         * Return is necessary for Promise.all()
         */
        return lookupFields;
      } else if (fieldMap.data_type === "ownerlookup") {
        /**
         * For Owner Lookup Fields
         */

        /**
         * Adding USer Fields
         */
        const lookupFields = await ZOHO.CRM.META.getFields({
          Entity: "users",
        });
        if (lookupFields.fields !== undefined) {
          returnData.fieldNames[fieldMap.field_label] = lookupFields.fields;
        }

        /**
         * Adding User Data
         */
        const lookupData = await ZOHO.CRM.API.getUser({
          ID: returnData.recordData[entity][fieldMap.api_name].id,
        });
        if (lookupData !== undefined) {
          returnData.recordData[fieldMap.field_label] = lookupData.users[0];
        }
        /**
         * Return is necessary
         */
        return lookupFields;
      }
    })
  ).then((data) => {
    /**
     * After all finished return data
     */
    return returnData;
  });
};

// export const generateValidationSchema = (
//   formSchema,
//   parentKey = "",
//   seperator = "."
// ) => {
//   let validationSchema = {};
//   formSchema?.inputs.forEach((inputProps) => {
//     if (inputProps.type == "multistep") {
//       inputProps.stepsSchema.forEach((stepSchema) => {
//         const path =
//           parentKey === ""
//             ? inputProps.name
//             : parentKey + "__" + inputProps.name;
//         validationSchema = {
//           ...validationSchema,
//           ...generateValidationSchema(stepSchema, path, seperator),
//         };
//       });
//     } else {
//       const path =
//         parentKey === "" ? inputProps.name : parentKey + "__" + inputProps.name;
//       const validators = inputProps?.validators || [];
//       let inputValidationSchema = {};
//       validators.forEach((validator) => {
//         switch (validator) {
//           case "string":
//             if (Object.keys(inputValidationSchema).length === 0) {
//               inputValidationSchema = Joi.string();
//             } else {
//               inputValidationSchema.string();
//             }

//             break;
//           case "any":
//             if (Object.keys(inputValidationSchema).length === 0) {
//               inputValidationSchema = Joi.any();
//             } else {
//               inputValidationSchema.any();
//             }

//             break;
//           case "number":
//             if (Object.keys(inputValidationSchema).length === 0) {
//               inputValidationSchema = Joi.number();
//             } else {
//               inputValidationSchema.number();
//             }

//             break;
//           case "url":
//             if (Object.keys(inputValidationSchema).length === 0) {
//               inputValidationSchema = Joi.string().dataUri();
//             } else {
//               inputValidationSchema.string().dataUri();
//             }
//             break;

//           case "required":
//             if (Object.keys(inputValidationSchema).length === 0) {
//               inputValidationSchema = Joi.required();
//             } else {
//               inputValidationSchema.required();
//             }
//             break;
//           default:
//             break;
//         }
//       });
//       if (Object.keys(inputValidationSchema).length > 0) {
//         validationSchema[path] = inputValidationSchema.label(inputProps.label);
//       } else {
//         validationSchema[path] = Joi.any();
//       }
//     }
//   });
//   return validationSchema;
// };

export const iwebSearchZohoRecords = async (entity, query, settingType) => {
  const settingsDataResp = await ZOHO.CRM.API.searchRecord({
    Entity: entity,
    Type: "criteria",
    Query: query,
  });

  // Fallback for Newly Created Records
  const newCreatedResp = await ZOHO.CRM.API.getAllRecords({
    Entity: entity,
    sort_order: "desc",
    per_page: 10,
    page: 1,
  });
  const settings = [
    ...(settingsDataResp?.data || []),
    ...(newCreatedResp?.data || []),
  ];
  let settingsMap = {};
  settings?.forEach((setting) => {
    if (setting?.zworkflow__Settings_Type === settingType) {
      settingsMap[setting?.id] = setting;
    }
  });
  return Object.values(settingsMap);
};

export const iwebPersonalizedSettings = async (loginUserId, loginUserEmail) => {
  // search Records
  const settingsDataResp = await ZOHO.CRM.API.searchRecord({
    Entity: "zworkflow__ZWorkflow_Settings",
    Type: "criteria",
    Query: `((zworkflow__Settings_Type:equals:Addon) and ((zworkflow__Setting_Created_By_Email:equals:${loginUserEmail}) or (zworkflow__Share_Setting_to_Users:equals:All) or ((zworkflow__Share_Setting_to_Users:equals:Selected Users) and (zworkflow__Shared_to_Users:starts_with:*${loginUserId}*))))`,
  });

  // Fallback for Newly Created Records
  const newCreatedResp = await ZOHO.CRM.API.getAllRecords({
    Entity: "zworkflow__ZWorkflow_Settings",
    sort_order: "desc",
    per_page: 10,
    page: 1,
  });
  const settings = [
    ...(settingsDataResp?.data || []),
    ...(newCreatedResp?.data || []),
  ];
  let settingsMap = {};
  settings?.forEach((setting) => {
    let valid = true;
    if (valid && setting?.zworkflow__Settings_Type !== "Addon") {
      valid = false;
    }

    if (
      valid &&
      setting?.zworkflow__Share_Setting_to_Users === null &&
      setting?.zworkflow__Setting_Created_By_Email !== loginUserEmail
    ) {
      valid = false;
    }
    if (
      valid &&
      setting?.zworkflow__Share_Setting_to_Users === "Selected Users" &&
      !setting?.zworkflow__Shared_to_Users?.includes(loginUserId)
    ) {
      valid = false;
    } else {
    }
    console.log(
      "🚀 ~ file: helperJsFunction.js ~ line 725 ~ settings?.forEach ~ valid",
      valid,
      setting?.id,
      setting?.zworkflow__Share_Setting_to_Users === "Selected Users"
    );

    if (valid) {
      let settingId = setting?.id;
      /**
       * Authenticcated or Not Authenticated
       */
      if (
        setting?.zworkflow__Connection_Sharing ===
          "Users need to Authenticate Connection" &&
        setting?.zworkflow__Setting_Created_By_Email !== loginUserEmail
      ) {
        setting.authenticated = false;
      } else {
        setting.authenticated = true;
      }

      /**
       * Finding Parent ID, to show Base Setting or Personal Setting
       */
      if (
        setting?.zworkflow__Parent_Setting_ID &&
        setting?.zworkflow__Parent_Setting_ID !== "" &&
        setting?.zworkflow__Setting_Created_By_Email === loginUserEmail
      ) {
        settingId = setting?.zworkflow__Parent_Setting_ID;
      }
      if (
        setting?.zworkflow__Parent_Setting_ID &&
        setting?.zworkflow__Parent_Setting_ID !== "" &&
        setting?.zworkflow__Setting_Created_By_Email !== loginUserEmail
      ) {
        settingId = "";
      }

      if (settingId !== "" && !settingsMap[settingId]) {
        settingsMap[settingId] = setting;
      } else if (
        settingId !== "" &&
        settingsMap[settingId]?.zworkflow__Setting_Created_By_Email !==
          loginUserEmail &&
        setting?.zworkflow__Setting_Created_By_Email === loginUserEmail
      ) {
        settingsMap[settingId] = setting;
      }
    }
  });

  return Object.values(settingsMap) || [];
};

export const fetchByCOQL = async (orgId, apiKey, apiDomain, query) => {
  const config = {
    url: `${process.env?.REACT_APP_API_SERVER_URL}/data/zoho/crm/coql`,
    method: "post",
    data: { query: query, apiDomain: apiDomain },
    headers: {
      apikey: apiKey,
      orgid: orgId,
    },
  };
  console.log(
    "🚀 ~ file: helperJsFunction.js ~ line 811 ~ fetchByCOQL ~ config",
    config
  );

  const response = await axios
    .request(config)
    .catch((error) => console.log("814 ", error));

  return response?.data || { data: [] };
};
