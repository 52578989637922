import { useState, useEffect } from "react";
import "./App.css";
import FieldMapping from "./FieldMapping/_FieldMapping";

import { BuildTheme } from "./global/Theme-variable";
import { useSnapshot } from "valtio";
import { state } from "./AdminStore";
import { state as fieldMappingState } from "./FieldMapping/Store";
import { ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Authentication from "./Authentication/Authentication";
const ZOHO = window.ZOHO;

function App() {
  const [zohoLoaded, setZohoLoaded] = useState(false);
  const [existingData, setExistingData] = useState();

  const snap = useSnapshot(state);

  const customizer = {
    direction: "ltr",
    theme: `${snap.color}_THEME`,
    activeMode: snap.darkMode === true ? "dark" : "light",
  };
  const theme = BuildTheme(customizer);
  const screenXs = useMediaQuery(theme.breakpoints.only("xs"));
  const screenSm = useMediaQuery(theme.breakpoints.only("sm"));
  const screenMd = useMediaQuery(theme.breakpoints.only("md"));
  const screenLg = useMediaQuery(theme.breakpoints.only("lg"));
  const screenXl = useMediaQuery(theme.breakpoints.only("xl"));

  if (screenXs === true) {
    state.screenSize = "xs";
  }
  if (screenSm === true) {
    state.screenSize = "sm";
  }
  if (screenMd === true) {
    state.screenSize = "md";
  }
  if (screenLg === true) {
    state.screenSize = "lg";
  }
  if (screenXl === true) {
    state.screenSize = "xl";
  }
  const handleSave = async (data) => {
    console.log({ data });
    console.log(state.settingId);
    console.log("Test 123 ", {
      url: `${process.env.REACT_APP_ADMIN_API_URL}/db/planetscale/clients/settings`,
      method: "POST",
      data: {
        settingId: state?.settingId || "",
        settingSchema: data,
        showInRelatedList: false,
        moduleApiName: state.moduleApiName,
        settingType: "workflow",
      },
      headers: {
        orgid: state.orgId,
        apikey: state.apiKey,
        connname: "fieldmappingforcrm",
      },
    });

    const insertedData = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_API_URL}/db/planetscale/clients/settings`,
      method: "POST",
      data: {
        settingId: state?.settingId || "",
        settingSchema: data,
        showInRelatedList: false,
        moduleApiName: state.moduleApiName,
        settingType: "workflow",
      },
      headers: {
        orgid: state.orgId,
        apikey: state.apiKey,
        connname: "fieldmappingforcrm",
      },
    });
    console.log(JSON.stringify(insertedData));
    const settingId = insertedData?.data?.data?.setting_id || "";
    console.log(
      "🚀 ~ file: App.js ~ line 88 ~ handleSave ~ settingId",
      settingId
    );
    if (settingId !== "") {
      await ZOHO.CRM.ACTION.setConfig({
        settingId: settingId,
        orgid: snap.orgId,
        // apikey: snap.apiKey,
        connname: "fieldmappingforcrm",
      });
    }
  };

  useEffect(() => {
    initZoho();
  }, []);
  const initZoho = async () => {
    ZOHO.embeddedApp.on("PageLoad", async function (data) {
      console.log("🚀 ~ file: App.js ~ line 97 ~ data", data);

      state.moduleApiName = data?.module;
      state.settingId = data?.configdata?.settingId || "";
      fieldMappingState.fromModuleData.fromModuleName = data?.module;

      /**
       * Fetching Organization Values
       */
      const orgVariableApiNames = {
        apiKeys: [
          "zfieldmappingforcrm__Organization_ID",
          "zfieldmappingforcrm__API_KEY",
          "zfieldmappingforcrm__ZAPI_Key",
          "zfieldmappingforcrm__ZUID",
          "zfieldmappingforcrm__Data_Center_URL",
        ],
      };
      const orgVariables = await ZOHO.CRM.API.getOrgVariable(
        orgVariableApiNames
      );
      state.orgId =
        orgVariables?.Success?.Content?.zfieldmappingforcrm__Organization_ID?.value;
      state.apiKey =
        orgVariables?.Success?.Content?.zfieldmappingforcrm__API_KEY?.value;

      // Fetch Settings specific Setting ID.
      if (state.settingId) {
        const existingData = await axios.request({
          url: `${process.env.REACT_APP_ADMIN_API_URL}/db/planetscale/clients/settings/${state.settingId}`,
          method: "GET",
          headers: {
            orgid: state.orgId,
            apikey: state.apiKey,
            connname: "fieldmappingforcrm",
          },
        });
        setExistingData(
          JSON.parse(existingData?.data?.data?.setting_schema || "{}")
        );
      }
      setZohoLoaded(true);
      //Custom Bussiness logic goes here
    });
    ZOHO.embeddedApp.init();
  };

  const router = createBrowserRouter([
    {
      path: "/customaction",
      element: zohoLoaded ? (
        <FieldMapping handleSave={handleSave} existingData={existingData} />
      ) : (
        <></>
      ),
    },
    {
      path: "/settings",
      element: <Authentication />,
    },
    {
      path: "/",
      element: <>No Data in the Path</>,
    },
  ]);

  console.log(zohoLoaded);
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
