import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useSnapshot } from "valtio";
import { Container, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";

import { state } from "./Store";
import AppDialog from "./Organisms/AppDialog";
import AppButton from "./Atoms/AppButton";

export default function AddAndResetModule({
  handleFormSubmit,
  handleExpanded,
}) {
  const [dialogForResetModule, setDialogForResetModule] = useState(false);
  const stateSnap = useSnapshot(state);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <TextField
        label="From Module"
        value={stateSnap.fromModuleData.fromModuleName}
        disabled={true}
        size="small"
        sx={{ flex: 1 }}
      />
      <TextField
        placeholder="From Module layout"
        value={stateSnap.fromModuleData?.selectedLayout?.name}
        disabled={true}
        size="small"
        sx={{
          ml: 1.5,
        }}
      />
      {/* Dialog For Reset */}
      <AppDialog
        openDialog={dialogForResetModule}
        handleCancel={() => {
          setDialogForResetModule(false);
        }}
        handleOk={() => {
          //Reset
          state.reset();
          setDialogForResetModule(false);
          handleFormSubmit(false);
        }}
        dialogTitle="Do you want to Reset Module?"
        sx={{ minWidth: "320px" }}
      />
      {/* {stateSnap.fromModuleData.fromModuleName && (
        <Button
          onClick={() => {
            setDialogForResetModule(true);
          }}
          size="medium"
          variant="outlined"
          color="danger"
          sx={{
            ml: 1.5,
          }}
        >
          Reset
        </Button>
      )} */}
      {/* Add Module */}
      {/* {stateSnap.fromModuleData.fromModuleName && (
        <AppButton
          variant="outlined"
          size="small"
          sx={{ borderRadius: "25px", ml: 1.5 }}
          onClick={() => {
            //add container
            const containerId = uuidv4();
            state.addContainer({ containerId });
            handleExpanded(containerId);
          }}
        >
          Add Module
        </AppButton>
      )} */}
    </Box>
  );
}
