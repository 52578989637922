import { useEffect, useState, Fragment } from "react";

//Material Ui
import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { useSnapshot } from "valtio";
import { Container } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import getAllModules from "./getAllModules";
import Subform from "./SubForm";
import { state } from "./Store";
import { state as adminState } from "../AdminStore";
import SaveAndLoad from "./SaveAndLoad";
import DialogForFromModule from "./DialogForFromModule";
import ContainerSettings from "./ContainerSettings";
import ContainerFieldMapping from "./ContainerFieldMapping";
import ContainerToModuleAndLayout from "./ContainerToModuleAndLayout";
import DeleteContainer from "./DeleteContainer";
import SettingUpdate from "./SettingUpdate";
import AddAndResetModule from "./AddAndResetModule";
import { fetchByCOQL } from "../utls/helperJsFunction";
import getLayouts from "./getLayouts";
import getModuleFields from "./getModuleFields";

const _FieldMapping = ({ handleSave, existingData }) => {
  console.log(
    "🚀 ~ file: _FieldMapping.js ~ line 31 ~ existingData",
    existingData
  );

  const [loading, setLoading] = useState(true);
  const [loadingForEditing, setLoadingForEditing] = useState(true);

  //For fromModule
  const [isFromModuleSunbmit, setIsFromModuleSubmit] = useState(false);

  const [modules, setModules] = useState([]);

  const [error, setError] = useState("");

  const stateSnap = useSnapshot(state);
  console.log(
    "🚀 ~ file: _FieldMapping.js ~ line 48 ~ stateSnap",
    JSON.stringify(stateSnap)
  );

  ///NEW LAYOUT
  const [expanded, setExpanded] = useState(false);

  /**
   * For Connection Names used for ZOHO CRM
   */
  // const [connections, setConnections] = useState(["abcd", "efgh"]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    (async () => {
      try {
        // const ZOHO = await window.ZOHO;
        // const pageLoadData = await ZOHO.embeddedApp.on("PageLoad");
        // console.log(
        //   "🚀 ~ file: _FieldMapping.js ~ line 66 ~ pageLoadData",
        //   pageLoadData
        // );
        // await ZOHO.embeddedApp.init();
        // await ZOHO.CRM.UI.Resize({ height: "1200", width: "1000" });
        // return;

        setLoading(true);
        const modules = await getAllModules();

        /**
         * Filtering Modules
         */
        const filteredModules = modules.filter(
          (module) =>
            !module.web_link &&
            module.api_supported &&
            module.show_as_tab &&
            module.creatable
        );
        setModules(filteredModules);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log({ err });
        alert(err);
      }
    })();
  }, []);

  const loadDataFromDb = async (data) => {
    console.log({ data });
    try {
      //set settingName, settingDescription,connectionName
      // state.fromModuleData.settingName = data.settingName;
      // state.fromModuleData.settingDescription = data.settingDescription;
      // state.fromModuleData.connectionName = JSON.stringify(data.connectionName);

      //fields of all modules will be stored in the allModules
      const allModules = {};
      //fields of all subform modules will be stored in allSubformModules
      const allSubformModules = {};
      //layout info of all modules will be stored in layoutData
      const layoutData = {};
      //subform modules of all modules layout
      const subformModulesLayouts = {};
      allModules[data.fromModuleName] = { fields: [] };
      //grab all the module names from data.containers and populate them to allModules and allSubformModules
      data?.containers?.forEach((container) => {
        allModules[container.toModuleName] = {
          fields: [],
        };
        if (container.subforms?.length > 0) {
          container.subforms.forEach((subform) => {
            if (subform.fromModuleName) {
              allSubformModules[subform.fromModuleName] = {
                fields: [],
              };
            }
            if (subform.toModuleName) {
              allSubformModules[subform.toModuleName] = {
                fields: [],
              };
            }
          });
        }
      });
      const allModuleNames = Object.keys(allModules);
      const allSubformModuleNames = Object.keys(allSubformModules);
      console.log({
        allModuleNames,
        allSubformModuleNames,
      });
      //populate respective modules layout data to layoutData
      for (let i = 0; i < allModuleNames.length; i++) {
        const moduleName = allModuleNames[i];
        let { allLayouts, status, message, subformModulesOfLayouts } =
          await getLayouts(moduleName);
        if (status === "error") {
          alert(message);
          break;
        }
        layoutData[moduleName] = allLayouts;
        subformModulesLayouts[moduleName] = subformModulesOfLayouts;
      }
      //populate respective subform modules fields to allSubformModules

      for (let i = 0; i < allSubformModuleNames.length; i++) {
        const moduleName = allSubformModuleNames[i];
        const { ok, message, fields } = await getModuleFields(moduleName);
        if (!ok) {
          console.log({ message });
          alert(message);
        }
        allSubformModules[moduleName].fields = fields;
      }
      console.log({
        layoutData,
        allSubformModules,
        subformModulesLayouts,
      });
      //this part will handle fromModuleData
      const fromModuleData = {};
      // {
      const selectedLayout =
        layoutData[data.fromModuleName][data.selectedFromModuleLayout.id];
      const fields = layoutData[data.fromModuleName][selectedLayout.id].fields;
      //   // get all lookup modules from fromModuleFields
      const lookupModules = fields.flatMap((field) =>
        field.data_type === "lookup" ? [field] : []
      );
      console.log({ lookupModules });
      //   // it contains all fields include lookupmodule fields (for fromModuleFields)
      let allfields_include_lookupmodule_fields = [];
      //   // fields from main module and push those fields to allfields_include_lookupmodule_fields
      for (let field = 0; field < fields.length; field++) {
        allfields_include_lookupmodule_fields.push({
          ...fields[field],
          moduleName: data.fromModuleName,
        });
      }
      //   //grab all lookup module fields and push those fields to allfields_include_lookupmodule_fields
      for (
        let lookupModule = 0;
        lookupModule < lookupModules.length;
        lookupModule++
      ) {
        let getLookupFields = await getModuleFields(
          lookupModules[lookupModule].lookup.module.api_name
        );
        getLookupFields.fields.forEach((lookupField) => {
          allfields_include_lookupmodule_fields.push({
            ...lookupField,
            moduleName: lookupModules[lookupModule].field_label,
            lookupfield_api_name:
              data.fromModuleName + "." + lookupModules[lookupModule].api_name,
          });
        });
      }
      state.fromModuleData.fromModuleName = data.fromModuleName;
      state.fromModuleData.fields = [
        ...allfields_include_lookupmodule_fields,
        {
          json_type: "string",
          field_label: "Current Date",
          required: false,
          display_label: "Current Date",
          id: "47314411111122081018",
          api_name: "Current_Execution__Date",
          data_type: "date",
          moduleName: "Custom",
        },
        {
          json_type: "string",
          field_label: "Current Date Time",
          required: false,
          display_label: "Current Date Time",
          id: "47314411111122081019",
          api_name: "Current_Execution__Date_Time",
          data_type: "datetime",
          moduleName: "Custom",
        },
        {
          json_type: "jsonobject",
          field_label: "Logged-In User",
          required: false,
          display_label: "Logged-In User",
          id: "1111122081019",
          api_name: "Logged_In_User",
          data_type: "ownerlookup",
          moduleName: "Custom",
        },
      ];
      state.fromModuleData.subformModules =
        subformModulesLayouts[data.fromModuleName][selectedLayout.id];
      state.fromModuleData.selectedLayout = data.selectedFromModuleLayout;
      state.fromModuleData.subformModulesOfLayouts =
        subformModulesLayouts[data.fromModuleName];
      state.fromModuleData.allowedTypes = {
        text: true,
        textarea: true,
      };
      state.fromModuleData.error = null;
      state.fromModuleData.loading = false;
      console.log({ fromModuleData });
      //   //set settingName, settingDescription,connectionName
      state.fromModuleData.settingName = data.settingName;
      state.fromModuleData.settingDescription = data.settingDescription;
      state.fromModuleData.connectionName = JSON.stringify(data.connectionName);
      state.fromModuleData.deleteRecord = data.deleteRecord;
      state.fromModuleData.recordId = data.record_id;
      // state.fromModuleData = fromModuleData;
      // }
      //this part will handle containers
      {
        const totalContainers = Object.keys(data.containers).length;
        //get layouts data of toModule
        for (let i = 0; i < totalContainers; i++) {
          const toModuleName = data.containers[i].toModuleName;
          let { selectedLayout, allLayouts, status, message } =
            await getLayouts(toModuleName);
          layoutData[toModuleName] = allLayouts;
          if (status === "error") {
            alert(message);
            break;
          }
        }
        console.log({
          layoutData,
          allSubformModules,
          subformModulesLayouts,
        });
        const containers = {};
        data.containers.forEach((container, index) => {
          const containerData = {};
          const subformData = {};
          const toModuleName = container.toModuleName;
          const fromModuleName = data.fromModuleName;
          const selectedLayoutId = container.selectedLayout.id;
          containerData.toModuleName = container.toModuleName;
          containerData.error = null;
          containerData.toModuleFields =
            layoutData[toModuleName][selectedLayoutId].fields;
          containerData.fieldMapping = container.fieldMapping;
          containerData.layoutData = layoutData[toModuleName];
          containerData.selectedLayout = container.selectedLayout;
          containerData.shouldSubformAdd = container.shouldSubformAdd;
          containerData.subformModules = {
            to: subformModulesLayouts[toModuleName][selectedLayoutId],
            from: subformModulesLayouts[fromModuleName][
              data.selectedFromModuleLayout.id
            ],
          };
          containerData.subformModulesOfLayouts =
            subformModulesLayouts[toModuleName];
          containerData.allowedTypes = {
            text: true,
            textarea: true,
          };
          containerData.loading = false;
          container.subforms?.forEach((subform) => {
            if (subform.fromModuleName && subform.toModuleName) {
              const data = {};
              const subformId = subform.subformId;
              const fromModuleName = subform.fromModuleName;
              const toModuleName = subform.toModuleName;
              const fromModuleFields = allSubformModules[fromModuleName].fields;
              const toModuleFields = allSubformModules[toModuleName].fields;
              const fieldMapping = subform.fieldMapping;
              data.fromModuleData = {
                fromModuleName: fromModuleName,
                fields: fromModuleFields,
                error: null,
                allowedTypes: {
                  text: true,
                  textarea: true,
                },
                loading: false,
              };
              data.toModuleData = {
                toModuleName: toModuleName,
                error: null,
                toModuleFields: toModuleFields,
                loading: false,
              };
              data.subformId = subformId;
              data.fieldMapping = fieldMapping;
              subformData[subformId] = data;
            }
          });
          containerData.settingName = container.settingName;
          containerData.settings = container.settings;
          containerData.subforms = {
            containers: subformData,
          };
          containers[index] = containerData;
        });

        console.log({ fromModuleData, containers });

        state.containers = containers;
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  useEffect(() => {
    (async () => {
      // Remvoed setting Name as it is not required now
      if (existingData?.fromModuleName) {
        setIsFromModuleSubmit(true);
        setLoading(true);
        await loadDataFromDb(existingData);
        setLoading(false);
      } else {
        //BUG Due to Reset State data is Resetting. hence Commentd by Emran
        // state.reset();
        // test
      }
    })();
  }, [existingData]);

  // show loader while modules is being fetched
  if (loading) {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={70} color="secondary" />
      </Box>
    );
  }

  if (!isFromModuleSunbmit) {
    return (
      <DialogForFromModule
        modules={modules}
        handleFromModuleSubmit={() => setIsFromModuleSubmit(true)}
      />
    );
  }
  if (
    existingData &&
    Object.keys(stateSnap.containers).length === 0 &&
    loadingForEditing
  ) {
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={70} color="secondary" />
      </Box>
    );
  }

  return (
    <Box style={{ width: "100%" }}>
      <Box
        sx={{
          boxShadow:
            "0px 22px 80px rgba(0, 0, 0, 0.07), 0px 2.75474px 10.0172px rgba(0, 0, 0, 0.035)",
          background: "#fff",
          borderRadius: "12px",
        }}
        p={3}
      >
        {/* if there is existing data that means user want to edit fieldmapping
        we wont show the AddAndResetModule module until containers populated into the global state */}
        {existingData && Object.keys(stateSnap.containers).length > 0 && (
          <Box style={{ display: "flex" }} mb={2}>
            <Box style={{ flex: 1 }}>
              <AddAndResetModule
                handleFormSubmit={(arg) => setIsFromModuleSubmit(arg)}
                handleExpanded={(arg) => setExpanded(arg)}
              />
            </Box>
            <Box sx={{ marginLeft: "12px" }}>
              <SaveAndLoad
                handleSave={handleSave}
                handleExpanded={(arg) => setExpanded(arg)}
                existingData={existingData}
                addModule={false}
              />
            </Box>
          </Box>
        )}
        {/* if there is no existing data found that means user want create fieldmapping */}
        {!existingData && (
          <Box style={{ display: "flex" }} mb={2}>
            <Box style={{ flex: 1 }}>
              <AddAndResetModule
                handleFormSubmit={(arg) => setIsFromModuleSubmit(arg)}
                handleExpanded={(arg) => setExpanded(arg)}
              />
            </Box>
            <Box sx={{ marginLeft: "12px" }}>
              <SaveAndLoad
                handleSave={handleSave}
                handleExpanded={(arg) => setExpanded(arg)}
                existingData={existingData}
                addModule={false}
              />
            </Box>
          </Box>
        )}

        {Object.keys(stateSnap.containers)?.map(
          (containerId, containerIndex) => {
            const totalContainers = containerIndex - 1;
            const container = Object.values(stateSnap.containers);
            const allCustomModules = [];
            for (let i = 0; i <= totalContainers; i++) {
              const setting = {
                api_name: null,
                field_label: null,
                lookupfield_api_name: container[i].toModuleName,
                moduleName: "Custom",
              };
              setting.api_name = container[i].settingName;
              setting.field_label = container[i].settingName;
              allCustomModules.push(setting);
            }
            allCustomModules.push({
              api_name:
                stateSnap.fromModuleData.fromModuleName + "_" + "Record_Id",
              field_label:
                stateSnap.fromModuleData.fromModuleName + "_" + "Record_Id",
              lookupfield_api_name: stateSnap.fromModuleData.fromModuleName,
              moduleName: "Custom",
            });

            return (
              <>
                {/* Container */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  mt={1}
                >
                  <Box sx={{ flex: 1 }}>
                    <Accordion
                      expanded={expanded === containerId}
                      onChange={handleChange(containerId)}
                      sx={{
                        boxShadow: "none",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                          background: "#F6F6F6",
                        }}
                      >
                        <Typography
                          sx={{
                            flexShrink: 0,
                            fontWeight: 600,
                          }}
                        >
                          {stateSnap.containers[containerId].settingName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          background: "#FAFCFE",
                        }}
                      >
                        <Box mt={1}>
                          <ContainerToModuleAndLayout
                            modules={modules}
                            containerId={containerId}
                          />
                        </Box>

                        {stateSnap.containers[containerId].toModuleFields
                          .length > 0 && (
                          <Box mt={1}>
                            <ContainerFieldMapping
                              containerId={containerId}
                              allCustomModules={allCustomModules}
                            />
                          </Box>
                        )}

                        {/* Add Subform */}
                        {state.containers[containerId].shouldSubformAdd && (
                          <Box mt={1}>
                            <Subform
                              subformModules={
                                stateSnap.containers[containerId].subformModules
                              }
                              containerId={containerId}
                            />
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box ml={1} mt={1}>
                    <DeleteContainer
                      containerId={containerId}
                      setLoadingForEditing={setLoadingForEditing}
                    />
                  </Box>
                </Box>
              </>
            );
          }
        )}
        <Box mt={2}>
          <SaveAndLoad
            handleSave={handleSave}
            handleExpanded={(arg) => setExpanded(arg)}
            existingData={existingData}
          />
        </Box>
        {error && (
          <Box my={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default _FieldMapping;
