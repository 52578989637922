import React from "react";
//Material Ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { useSnapshot } from "valtio";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import { state } from "./Store";
import SelectToModuleField from "./SelectToModuleField";
import SelectDefaultFieldValue from "./SelectDefaultFieldValue";
import SelectFromModuleFields from "./SelectFromModuleFields";
import handleAutoMapping from "./handleAutoMapping";

export default function ContainerFieldMapping({
  containerId,
  allCustomModules,
}) {
  const stateSnap = useSnapshot(state);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#E9F5FF",
          cursor: "pointer",
          p: {
            xs: 1,
          },
        }}
        onClick={() =>
          (state.containers[containerId].isOpenFieldMapping =
            !state.containers[containerId].isOpenFieldMapping)
        }
      >
        <Typography sx={{ color: "#048DFF", fontWeight: 600 }}>
          Field Mapping
        </Typography>
        {stateSnap.containers[containerId].isOpenFieldMapping ? (
          <ArrowDropUpIcon />
        ) : (
          <ArrowDropDownIcon />
        )}
      </Box>

      <Collapse in={stateSnap.containers[containerId].isOpenFieldMapping}>
        <Box
          sx={{
            background: "#fff",
            p: {
              xs: 1,
            },
          }}
        >
          {/* AutoMapping Fields */}
          {/* <Box mb={2}>
            {!stateSnap.containers[containerId].error &&
              stateSnap.containers[containerId].toModuleName && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    state.containers[containerId].loading = true;
                    const autoMappedFields = handleAutoMapping({
                      toModuleFields: [
                        ...stateSnap.containers[containerId].toModuleFields,
                      ],
                      fromModuleFields: [
                        ...stateSnap.fromModuleData.fields.filter(
                          (field) =>
                            field.moduleName ===
                            stateSnap.fromModuleData.fromModuleName
                        ),
                      ],
                      fieldMapping: [
                        ...stateSnap.containers[containerId].fieldMapping,
                      ],
                    });
                    console.log({ autoMappedFields });
                    state.containers[containerId].loading = false;
                    state.containers[containerId].fieldMapping =
                      autoMappedFields;
                  }}
                >
                  Auto Mapping
                </Button>
              )}
          </Box> */}
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant="h6" textAlign={"center"}>
                To Module Field
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h6" textAlign={"center"}>
                From Module Field
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" textAlign={"center"}>
                Default Value
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ width: "28px" }} />
            </Grid>
          </Grid>
          {/* Field Mapping */}
          <Grid container spacing={1.5} mt={0.5}>
            {stateSnap.containers[containerId].fieldMapping.map(
              (field, index) => {
                //grab fields of from module based on the data_type of to module field
                let fieldsOfFromModule;
                const filteredCustomFields = allCustomModules.filter(
                  (customModule) =>
                    customModule.lookupfield_api_name ===
                    field.to?.lookup?.module?.api_name
                );

                if (
                  field?.to?.data_type === "text" ||
                  field?.to?.data_type === "textarea"
                ) {
                  fieldsOfFromModule = [
                    ...stateSnap.fromModuleData.fields,
                    ...filteredCustomFields,
                  ];
                } else if (field?.to?.data_type === "lookup") {
                  fieldsOfFromModule = [
                    ...stateSnap.fromModuleData.fields.filter(
                      (moduleField) =>
                        moduleField.data_type === field?.to?.data_type && // moduleField.lookup_data.module.api_name == field.to.lookup_data.module.api_name
                        moduleField.lookup.module.api_name ===
                          field?.to?.lookup?.module?.api_name
                    ),
                    ...filteredCustomFields,
                  ];
                } else {
                  fieldsOfFromModule = stateSnap.fromModuleData.fields.filter(
                    (moduleField) =>
                      moduleField.data_type === field?.to?.data_type
                  );
                }
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1.5}
                    alignItems="center"
                  >
                    <Grid item xs>
                      <SelectToModuleField
                        fields={stateSnap.containers[
                          containerId
                        ].toModuleFields.filter((field) => {
                          const mappedFields =
                            stateSnap.containers[containerId].fieldMapping;

                          const fieldExistInFieldMapping = mappedFields.find(
                            (mappedField) =>
                              mappedField?.to?.api_name === field.api_name
                          );
                          if (fieldExistInFieldMapping) {
                            return false;
                          } else {
                            return true;
                          }
                        })}
                        label="Select To Module Field"
                        fieldIndex={index}
                        fieldData={field}
                        setFieldMapping={(event, value) => {
                          console.log("To", {
                            field,
                            value,
                          });
                          // set text to from fields in fieldmapping
                          const updatedFieldMapping = stateSnap.containers[
                            containerId
                          ].fieldMapping.map((mappedField) => {
                            if (mappedField.id !== field.id) {
                              return mappedField;
                            } else {
                              return {
                                ...mappedField,
                                to: {
                                  api_name: value?.api_name ?? "",
                                  data_type: value?.data_type ?? "",
                                  field_label: value?.field_label ?? "",
                                  ...(value.data_type === "picklist" && {
                                    pick_list_values: value.pick_list_values,
                                  }),
                                  ...(value.data_type ===
                                    "multiselectpicklist" && {
                                    pick_list_values: value.pick_list_values,
                                  }),
                                  ...(value.data_type === "lookup" && {
                                    lookup: value.lookup,
                                  }),
                                },
                              };
                            }
                          });
                          state.updateContainerFieldMapping({
                            containerId,
                            fieldMapping: updatedFieldMapping,
                          });
                        }}
                        fieldMapping={
                          stateSnap.containers[containerId].fieldMapping
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <SelectFromModuleFields
                        fields={fieldsOfFromModule}
                        fieldData={field}
                        fromModuleName={stateSnap.fromModuleData.fromModuleName}
                        allowedTypes={stateSnap.fromModuleData.allowedTypes}
                        setFieldMapping={(textareaValue) => {
                          console.log("From", {
                            field,
                            textareaValue,
                          });

                          const updatedFieldMapping = stateSnap.containers[
                            containerId
                          ].fieldMapping.map((mappedField) => {
                            if (mappedField.id !== field.id) {
                              return mappedField;
                            } else {
                              return {
                                ...mappedField,
                                from: textareaValue
                                  ? textareaValue
                                  : field.from,
                              };
                            }
                          });

                          state.updateContainerFieldMapping({
                            containerId,
                            fieldMapping: updatedFieldMapping,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <SelectDefaultFieldValue
                        data_type={field.to?.data_type}
                        pick_list_values={field.to?.pick_list_values}
                        defaultValue={field?.defaultValue?.value}
                        onChange={(value) => {
                          console.log({ defaultField: value });
                          if (
                            field.to.data_type === "text" ||
                            field.to.data_type === "textarea" ||
                            field.to.data_type === "phone" ||
                            field.to.data_type === "email" ||
                            field.to.data_type === "datetime" ||
                            field.to.data_type === "date" ||
                            field.to.data_type === "boolean" ||
                            field.to.data_type === "integer" ||
                            field.to.data_type === "double" ||
                            field.to.data_type === "currency"
                          ) {
                            const updatedFieldMapping = stateSnap.containers[
                              containerId
                            ].fieldMapping.map((mappedField) => {
                              if (mappedField.id !== field.id) {
                                return mappedField;
                              } else {
                                return {
                                  ...mappedField,
                                  defaultValue: {
                                    error: null,
                                    value,
                                  },
                                };
                              }
                            });
                            state.updateContainerFieldMapping({
                              containerId,
                              fieldMapping: updatedFieldMapping,
                            });
                          } else if (field.to.data_type === "picklist") {
                            const updatedFieldMapping = stateSnap.containers[
                              containerId
                            ].fieldMapping.map((mappedField) => {
                              if (mappedField.id !== field.id) {
                                return mappedField;
                              } else {
                                return {
                                  ...mappedField,
                                  defaultValue: {
                                    error: null,
                                    value,
                                  },
                                };
                              }
                            });
                            state.updateContainerFieldMapping({
                              containerId,
                              fieldMapping: updatedFieldMapping,
                            });
                          } else if (
                            field.to.data_type === "multiselectpicklist"
                          ) {
                            // set text to from fields in fieldmapping
                            const updatedFieldMapping = stateSnap.containers[
                              containerId
                            ].fieldMapping.map((mappedField) => {
                              if (mappedField.id !== field.id) {
                                return mappedField;
                              } else {
                                return {
                                  ...mappedField,
                                  defaultValue: {
                                    error: null,
                                    value:
                                      typeof value === "string"
                                        ? value.split(",")
                                        : value,
                                  },
                                };
                              }
                            });
                            state.updateContainerFieldMapping({
                              containerId,
                              fieldMapping: updatedFieldMapping,
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      {/* Delete Field */}
                      {!field.mandatory ? (
                        <Box>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            color="error"
                            onClick={() => {
                              state.deleteContainerField({
                                containerId,
                                fieldId: field.id,
                              });
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box sx={{ width: "28px" }} />
                      )}
                    </Grid>
                  </Grid>
                );
              }
            )}
          </Grid>
          {/* Add Field */}
          {state.containers[containerId].toModuleFields.length > 0 &&
            state.fromModuleData.fromModuleName && (
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ borderRadius: "25px" }}
                  onClick={() => state.addContainerField(containerId)}
                >
                  Add Field
                </Button>
              </Box>
            )}
        </Box>
      </Collapse>
    </>
  );
}
