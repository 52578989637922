import React, { useState, useMemo, useDeferredValue } from "react";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { format, formatISO } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { OneKPlusOutlined } from "@mui/icons-material";

export default function SelectDefaultFieldValue({
  defaultValue,
  data_type,
  pick_list_values,
  onChange,
}) {
  const [textValue, setTextValue] = useState(null);

  function debounce(func, timeout = 400) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const deferredName = useDeferredValue(textValue);
  const deffered = useMemo(() => {
    if (deferredName || deferredName === "") {
      onChange(deferredName);
    }
  }, [deferredName]);

  function handleChangeText(e) {
    setTextValue(e.target.value);
  }
  function handleChangeNumber(value) {
    setTextValue(value);
  }

  switch (data_type) {
    case "text":
      return (
        <TextField
          size="small"
          placeholder="Type default value"
          sx={{ width: "100%" }}
          value={textValue || defaultValue}
          onChange={handleChangeText}
        />
      );

    case "textarea":
      return (
        <TextField
          size="small"
          placeholder="Type default value"
          sx={{ width: "100%" }}
          multiline
          rows={4}
          value={textValue || defaultValue}
          onChange={handleChangeText}
        />
      );
    case "phone":
      return (
        <TextField
          size="small"
          placeholder="Type default value"
          sx={{ width: "100%" }}
          type="text"
          value={defaultValue}
          onChange={handleChangeText}
        />
      );
    case "email":
      return (
        <TextField
          size="small"
          placeholder="Type default value"
          sx={{ width: "100%" }}
          type="email"
          value={textValue || defaultValue}
          onChange={handleChangeText}
        />
      );
    case "picklist":
      return (
        <Select
          id="demo-simple-select-outlined"
          onChange={handleChangeText}
          sx={{ width: "100%" }}
          size="small"
          placeholder="Select Default Value"
          value={textValue || defaultValue}
          defaultValue="none"
        >
          <MenuItem disabled value="none">
            Select a Picklist
          </MenuItem>
          {pick_list_values?.map((option) => {
            return (
              <MenuItem key={option.id} value={option.actual_value}>
                {option.display_value}
              </MenuItem>
            );
          })}
        </Select>
      );

    case "multiselectpicklist":
      return (
        <Select
          multiple
          id="demo-simple-select-outlined"
          onChange={handleChangeText}
          value={defaultValue ?? []}
          sx={{
            width: "100%",
            maxWidth: "316px",
          }}
          size="small"
          placeholder="Select Default Value"
          defaultValue="none"
        >
          <MenuItem disabled value="none">
            Placeholder
          </MenuItem>

          {pick_list_values?.map((option) => {
            return (
              <MenuItem key={option.id} value={option.actual_value}>
                {option.display_value}
              </MenuItem>
            );
          })}
        </Select>
      );

    case "datetime":
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField {...props} size="small" sx={{ width: "100%" }} />
            )}
            placeholder="Select DateTimePicker"
            value={defaultValue ? defaultValue : Date.now()}
            onChange={(value) => {
              if (formatISO(value)) onChange(formatISO(value));
            }}
          />
        </LocalizationProvider>
      );

    case "date":
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            placeholder="Select Date"
            value={defaultValue ? defaultValue : null}
            onChange={(value) => {
              try {
                if (format(value, "yyyy-MM-dd"))
                  onChange(format(value, "yyyy-MM-dd"));
              } catch (err) {
                console.log(err);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" sx={{ width: "100%" }} />
            )}
          />
        </LocalizationProvider>
      );

    case "boolean":
      return (
        <Checkbox
          checked={defaultValue === true ? true : false}
          size="small"
          onChange={(e) => onChange(e.target.checked)}
        />
      );
    case "integer":
      return (
        <TextField
          size="small"
          type="number"
          placeholder="Type default value"
          sx={{ width: "100%" }}
          onChange={(e) => {
            handleChangeNumber(e.target.value.replace(/[^\d]+/gi, ""));
          }}
          value={textValue || defaultValue}
        />
      );
    case "double":
      return (
        <TextField
          size="small"
          type="text"
          placeholder="Type default value"
          sx={{ width: "100%" }}
          onChange={(e) => {
            handleChangeNumber(e.target.value.replace(/[^.\d]+/gi, ""));
          }}
          value={textValue || defaultValue}
        />
      );
    case "currency":
      return (
        <TextField
          size="small"
          type="number"
          placeholder="Type default value"
          sx={{ width: "100%" }}
          onChange={(e) => {
            handleChangeNumber(e.target.value.replace(/[^.\d]+/gi, ""));
          }}
          value={textValue || defaultValue}
        />
      );

    default:
      return (
        <TextField
          size="small"
          placeholder="Type default value"
          sx={{ width: "100%" }}
          type="number"
          disabled
          value={textValue || defaultValue}
        />
      );
    // code block
  }
}
