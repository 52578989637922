import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";

const AppButton = ({ children, type = "", onClick, loading, sx, ...rest }) => {
  switch (type) {
    case "loadingButton":
      return (
        <LoadingButton
          size="small"
          onClick={onClick}
          loading={loading}
          sx={{ textTransform: "capitalize", ...sx }}
          {...rest}
          disabled={loading}
        >
          {children}
        </LoadingButton>
      );
    default:
      return (
        <Button
          onClick={onClick}
          size="small"
          sx={{ textTransform: "capitalize", ...sx }}
          {...rest}
        >
          {children}
        </Button>
      );
  }
};
export default AppButton;
