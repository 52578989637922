import React from "react";
import Dialog from "@mui/material/Dialog";
import AppDialogTitle from "../Atoms/AppDialogTitle";
import AppDialogActions from "../Atoms/AppDialogActions";
import AppButton from "../Atoms/AppButton";
import { Box } from "@mui/system";

const AppDialog = ({
  openDialog,
  dialogTitle,
  loaderButton,
  loading,
  children,
  handleCancel,
  handleOk,
  showDialogActions = true,
  buttonText = "Ok",
  buttonVariant = "contained",
  buttonColor = "primary",
  sx,
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={2} sx={sx}>
        {dialogTitle && (
          <AppDialogTitle sx={{ margin: 0, padding: 0, fontSize: "1.4rem" }}>
            <Box mb={2}>{dialogTitle}</Box>
          </AppDialogTitle>
        )}
        <Box mb={2}>{children}</Box>
        {showDialogActions && (
          <AppDialogActions sx={{ margin: 0, padding: 0 }}>
            <AppButton onClick={handleCancel} variant="outlined">
              Cancel
            </AppButton>
            {loaderButton ? (
              <AppButton
                onClick={handleOk}
                variant="contained"
                loading={loading}
                type="loadingButton"
              >
                {buttonText}
              </AppButton>
            ) : (
              <AppButton
                onClick={handleOk}
                variant={buttonVariant}
                color={buttonColor}
              >
                {buttonText}
              </AppButton>
            )}
          </AppDialogActions>
        )}
      </Box>
    </Dialog>
  );
};
export default AppDialog;
