const getModuleFields = async (moduleName) => {
  //if there is no moduleName found in the argument then throw error
  if (!moduleName) {
    return new Error("Module Name is required.");
  }
  try {
    const ZOHO = await window.ZOHO;
    const response = await ZOHO.CRM.META.getFields({
      Entity: moduleName,
    });
    console.log(
      "🚀 ~ file: getModuleFields.js ~ line test ~ getModuleFields ~ response",
      moduleName,
      response?.fields
    );

    //check error
    if (response.status === "error") {
      let errorMsg = "";

      if (response.status === "error") {
        errorMsg += `${response.message} (${moduleName}) `;
      }

      return { ok: false, message: errorMsg };
    }

    /**
     * For those where I can Create Data, not system Maintained
     */
    const filteredFields = response?.fields?.filter(
      (indvFieldMap) => indvFieldMap?.view_type?.create
    );

    return { ok: true, fields: filteredFields };
  } catch (err) {
    return { ok: false, message: err.message };
  }
};
export default getModuleFields;
