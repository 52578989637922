import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useCaretPosition } from "react-use-caret-position";

import getCaretCorordinate from "./getCaretCorordinate";

export default function SelectFromModuleFields({
  fields,
  fieldData,
  setFieldMapping,
  allowedTypes,
  fromModuleName,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [textareaValue, setTextAreaValue] = useState(fieldData.from || "");

  const popOverRef = useRef();
  const autoCompleteRef = useRef();
  const { ref: textFieldRef, start, end, updateCaret } = useCaretPosition();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // set text to from fields in fieldmapping
    setFieldMapping(textareaValue);
  }, [textareaValue]);

  useLayoutEffect(() => {
    // set text to from fields in fieldmapping
    setTextAreaValue(fieldData.from || "");
  }, [fieldData.from]);

  return (
    <>
      <TextField
        id="outlined-multiline-static"
        placeholder={
          fieldData.to?.data_type === "text" ||
          fieldData.to?.data_type === "textarea"
            ? "Type anything or # to merge fields"
            : "Type # to merge fields"
        }
        variant="outlined"
        multiline
        rows={1}
        size="small"
        style={{ width: "100%" }}
        disabled={!fieldData.to || fieldData.to.api_name == "Currency"}
        aria-describedby={id}
        inputRef={textFieldRef}
        value={textareaValue}
        onChange={(e) => {
          if (
            !e.target.value.includes("#") &&
            allowedTypes[fieldData?.to?.data_type]
          ) {
            setTextAreaValue(e.target.value);
            updateCaret();
          }
        }}
        onKeyPress={(event) => {
          const grabKeycodeOfLastLetter = event.which || event.keyCode;
          if (grabKeycodeOfLastLetter === 35) {
            handlePopoverOpen(event);
            updateCaret();
            //We need to delay to open the popover in order order to calculate the width of the popover
            setTimeout(() => {
              var coordinates = getCaretCorordinate(event.target, end, {
                debug: false,
              });
              console.log(autoCompleteRef.current);
              const windowouterWidth = window.outerWidth;

              let popOverWidth =
                popOverRef.current.querySelector(
                  ".MuiPopover-paper"
                ).clientWidth;

              //popOver position
              let left;
              let top =
                (
                  document.documentElement ||
                  document.body.parentNode ||
                  document.body
                ).scrollTop + coordinates.top;
              const isThereRoomForPopoverWidth =
                windowouterWidth > popOverWidth;

              // is there space to show popover then show popover next to the letter otherwise deduct the popover width from left(const)
              if (isThereRoomForPopoverWidth) {
                left =
                  event.target.offsetLeft -
                  event.target.scrollLeft +
                  coordinates.left +
                  14;
              } else {
                left =
                  event.target.offsetLeft -
                  event.target.scrollLeft +
                  coordinates.left +
                  14 -
                  popOverWidth;
              }
              autoCompleteRef.current.focus();

              setTop(top);
              setLeft(left);
            }, 10);
          }
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        ref={popOverRef}
        style={{
          position: "absolute",
          top: `${
            window.pageYOffset + 40 ||
            (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop + 40
          }px`,
          left: `${left - 10}px`,
          padding: "20px",
          height: "100%",
        }}
      >
        <Autocomplete
          autoHighlight={true}
          size="small"
          options={fields}
          // {...(fieldData?.["mandatory"] && { value: fields[fieldIndex] })}
          getOptionLabel={(option) => option.field_label}
          // disabled={fieldData?.["mandatory"]}
          sx={{ width: 300, padding: "10px" }}
          groupBy={(option) => option.moduleName}
          disableClearable={true}
          onChange={(e, value) => {
            console.log({
              length:
                value.api_name.length +
                (value?.lookupfield_api_name?.length ||
                  value?.moduleName?.length),
            });
            console.log(textFieldRef.current);

            const keywordValue =
              "$" +
              "{" +
              (value.moduleName.toString().toUpperCase() !== "CUSTOM"
                ? value?.lookupfield_api_name || value.moduleName
                : "Custom") +
              "." +
              value.api_name +
              "}";

            // setSelectedField(e.target.textContent);
            function setCaretPosition(textFieldRef, caretPos) {
              var el = textFieldRef.current;

              // ^ this is used to not only get "focus", but
              // to make sure we don't have it everything -selected-
              // (it causes an issue in chrome, and having it doesn't hurt any other browser)

              if (el !== null) {
                if (el.createTextRange) {
                  var range = el.createTextRange();
                  range.move("character", caretPos);
                  range.select();
                  return true;
                } else {
                  // (el.selectionStart === 0 added for Firefox bug)
                  if (el.selectionStart || el.selectionStart === 0) {
                    el.focus();
                    el.setSelectionRange(caretPos, caretPos);
                    return true;
                  } else {
                    // fail city, fortunately this never happens (as far as I've tested) :)
                    el.focus();
                    return false;
                  }
                }
              }
            }
            console.log({ start, end });
            //if to module field's data_type is text or textarea
            if (
              fieldData?.to?.data_type === "text" ||
              fieldData?.to?.data_type === "textarea"
            ) {
              setTextAreaValue(
                (prevValue) =>
                  prevValue.slice(0, start) +
                  keywordValue +
                  prevValue.slice(end)
              );
              handlePopoverClose();
              setTimeout(() => {
                setCaretPosition(
                  textFieldRef,
                  keywordValue.toString().length + start
                );
              }, 5);
              return;
            }

            //if to module field's data_type is not text or textarea
            const isThereAnyKeyword = /\$\{.+\}/.test(textareaValue);

            let newText;
            // if any keyword found then replace it
            if (isThereAnyKeyword) {
              newText = textareaValue.replace(/\$\{.+\}/, keywordValue);

              setTextAreaValue(newText);
            } else {
              setTextAreaValue(
                (prevValue) =>
                  prevValue.slice(0, start) +
                  keywordValue +
                  prevValue.slice(end)
              );
              setTimeout(() => {
                setCaretPosition(
                  textFieldRef,
                  keywordValue.toString().length + start
                );
              }, 5);
            }

            handlePopoverClose();
          }}
          renderInput={(params) => (
            <TextField
              inputRef={autoCompleteRef}
              {...params}
              label="From Module Fields"
            />
          )}
        />
      </Popover>
    </>
  );
}
