import { proxy } from "valtio";
import { devtools } from "valtio/utils";
//UUID
import { v4 as uuidv4 } from "uuid";

let state = proxy({
  fromModuleData: {
    fromModuleName: "Contacts",
    fields: [],
    subformModules: [],
    selectedLayout: {
      id: null,
      name: null,
    },
    recordId: null,
    settingName: "",
    settingDescription: "",
    connectionName: null,
    deleteRecord: false,
    subformModulesOfLayouts: {},
    layoutData: {},
    allowedTypes: { text: true, textarea: true },
    error: null,
    loading: false,
  },
  containers: {},

  updateFromModuleData: ({
    layoutData,
    subformModulesOfLayouts,
    fromModuleName,
  }) => {
    state.fromModuleData.layoutData = layoutData;
    state.fromModuleData.subformModulesOfLayouts = subformModulesOfLayouts;
    state.fromModuleData.fromModuleName = fromModuleName;
  },
  updateContainerData: ({
    containerId,
    subformModules,
    settingName,
    shouldSubformAdd,
    layoutData,
    selectedLayout,
    fieldMapping,
    toModuleFields,
    subformModulesOfLayouts,
  }) => {
    state.containers[containerId].subformModules = subformModules;
    state.containers[containerId].settingName = settingName;
    state.containers[containerId].shouldSubformAdd = shouldSubformAdd;
    state.containers[containerId].layoutData = layoutData;
    state.containers[containerId].selectedLayout = selectedLayout;
    state.containers[containerId].fieldMapping = fieldMapping;
    state.containers[containerId].toModuleFields = toModuleFields;
    state.containers[containerId].subformModulesOfLayouts =
      subformModulesOfLayouts;
  },
  updateContainerFieldMapping: ({ containerId, fieldMapping }) => {
    state.containers[containerId].fieldMapping = fieldMapping;
  },
  updateContainerLayout: ({ containerId, layoutId }) => {
    const findLayout = Object.values(
      state.containers[containerId].layoutData
    ).find((layout) => Number(layout.id) === Number(layoutId));

    console.log(
      { containerId, layoutId, findLayout },
      state.containers[containerId].subformModulesOfLayouts[layoutId]
    );

    if (findLayout) {
      state.containers[containerId].selectedLayout = findLayout;
      state.containers[containerId].fieldMapping = findLayout.mandatoryFields;
      state.containers[containerId].toModuleFields = findLayout.fields;
    }
    state.containers[containerId].subformModules.to =
      state.containers[containerId].subformModulesOfLayouts[layoutId];
  },
  updateContainerSetting: ({ containerId, settings }) => {
    state.containers[containerId].settings = settings;
  },
  addContainer: ({ containerId }) => {
    const settingNumber = Number(Object.keys(state.containers).length) + 1;
    const settingName =
      state.fromModuleData.fromModuleName + "_" + settingNumber;

    state.containers[containerId] = {
      toModuleName: null,
      error: null,
      isOpenFieldMapping: true,
      toModuleFields: [],
      fieldMapping: [],
      layoutData: [],
      selectedLayout: null,
      shouldSubformAdd: false,
      subformModules: { to: [], from: [] },
      allowedTypes: { text: true, textarea: true },
      subformDataFromDb: null,
      loading: false,
      subforms: { containers: {} },
      settingName: settingName,
      settings: [],
    };
  },
  addContainerField: (containerId) => {
    state.containers[containerId].fieldMapping.push({
      id: uuidv4(),
      mandatory: false,
      to: null,
      from: null,
    });
  },
  deleteContainerField: ({ containerId, fieldId }) => {
    const filteredFieldMapping = state.containers[
      containerId
    ].fieldMapping.filter((mappedField) => mappedField.id !== fieldId);
    state.containers[containerId].fieldMapping = filteredFieldMapping;
  },
  reset: () => {
    state.fromModuleData = {
      fromModuleName: "",
      fields: [],
      subformModules: [],
      selectedLayout: {
        id: null,
        name: "",
      },
      recordId: null,
      settingName: "",
      settingDescription: "",
      connectionName: null,
      subformModulesOfLayouts: {},
      layoutData: {},
      allowedTypes: { text: true, textarea: true },
      error: null,
      loading: false,
    };
    state.containers = {};
  },
  deleteContainer: (containerId) => {
    delete state.containers[containerId];
  },
  addSubform: ({ containerId }) => {
    const subformContainrId = uuidv4();
    state.containers[containerId].subforms.containers[subformContainrId] = {
      fromModuleData: {
        fromModuleName: null,
        fields: [],
        error: false,
        allowedTypes: { text: true, textarea: true },
        loading: false,
      },
      toModuleData: {
        toModuleName: null,
        error: false,
        toModuleFields: [],
        subformDataFromDb: null,
        loading: false,
      },
      subformId: subformContainrId,
      isSubformOpen: true,
      fieldMapping: [],
    };
  },
  updateSubformFieldMapping: ({ containerId, subformId, fieldMapping }) => {
    state.containers[containerId].subforms.containers[subformId].fieldMapping =
      fieldMapping;
  },
  addSubformField: ({ containerId, subformId }) => {
    state.containers[containerId].subforms.containers[
      subformId
    ].fieldMapping.push({
      id: uuidv4(),
      mandatory: false,
      to: null,
      from: null,
    });
  },
  deleteSubform: ({ containerId, subformId }) => {
    delete state.containers[containerId].subforms.containers[subformId];
  },
});

devtools(state, "App Reducer");

export { state };
