import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

export default function Module({
  modules,
  moduleName,
  setModuleName,
  label,
  disabled,
  error,
  loading,
  sx,
}) {
  return (
    <Autocomplete
      size="small"
      id="combo-box-demo"
      options={modules.filter((module) => module.api_supported)}
      getOptionLabel={(option) => option.plural_label}
      onChange={(event, value) => {
        setModuleName(value?.api_name ?? null);
      }}
      value={
        modules[modules.findIndex((field) => field.api_name === moduleName)] ||
        null
      }
      loading={loading}
      disableClearable={false}
      sx={{ ...sx, width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress  color="secondary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      disabled={disabled}
    />
  );
}
