import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  TextField,
  Typography,
  Icon,
} from "@mui/material";
import { useSnapshot } from "valtio";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

//UUID
import { v4 as uuidv4 } from "uuid";

import { state } from "./Store";
import getModuleFields from "./getModuleFields";
import SelectToModuleField from "./SelectToModuleField";
import SelectFromModuleFields from "./SelectFromModuleFields";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppDialog from "./Organisms/AppDialog";

export default function NewSubform({ subformModules, containerId }) {
  const [dialogForSubformDelete, setDialogForSubformDelete] = useState(false);
  const stateSnap = useSnapshot(state);
  console.log({
    to: subformModules.to?.length,
    from: subformModules.from?.length,
    subformLength: state.containers[containerId].subforms?.length?.containers,
  });
  return (
    <>
      {Object.values(stateSnap.containers[containerId].subforms.containers).map(
        (subform, index) => {
          return (
            <Box sx={{ display: "flex", alignItems: "flex-start" }} mt={2}>
              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#F8EEFF",
                    cursor: "pointer",
                  }}
                  p={1.5}
                  onClick={() =>
                    (state.containers[containerId].subforms.containers[
                      subform.subformId
                    ].isSubformOpen =
                      !state.containers[containerId].subforms.containers[
                        subform.subformId
                      ].isSubformOpen)
                  }
                >
                  <Typography sx={{ color: "#9900FF", fontWeight: 600 }}>
                    Subform-{index + 1}
                  </Typography>
                  {stateSnap.containers[containerId].subforms.containers[
                    subform.subformId
                  ].isSubformOpen ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </Box>

                <Collapse
                  in={
                    stateSnap.containers[containerId].subforms.containers[
                      subform.subformId
                    ].isSubformOpen
                  }
                >
                  <Box p={2} sx={{ background: "#fff" }}>
                    <Grid container xs={12} spacing={2} alignItems="center">
                      <Grid item xs>
                        {/* Select To Module */}
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={subformModules.to.filter((subformModule) => {
                            // is module is already found in subforms.containers[id].toModuleData then deduct that module from options
                            const isSelectedForMapping = Object.values(
                              state.containers[containerId].subforms.containers
                            ).find(
                              (subformContainer) =>
                                subformContainer.toModuleData.toModuleName ===
                                subformModule.api_name
                            );
                            if (isSelectedForMapping) {
                              return false;
                            } else {
                              return true;
                            }
                          })}
                          value={subformModules.to.find(
                            (toModule) =>
                              toModule.api_name ===
                              stateSnap.containers[containerId].subforms
                                .containers[subform.subformId].toModuleData
                                .toModuleName
                          )}
                          getOptionLabel={(option) => option.field_label}
                          onChange={async (event, value) => {
                            console.log({ value });

                            state.containers[containerId].subforms.containers[
                              subform.subformId
                            ].toModuleData.error = null;

                            state.containers[containerId].subforms.containers[
                              subform.subformId
                            ].toModuleData.loading = true;

                            let { ok, message, fields } = await getModuleFields(
                              value.api_name
                            );
                            console.log(
                              "🚀 ~ file: SubForm.js ~ line test ~ onChange={ ~ fields",
                              fields
                            );
                            //check error
                            if (!ok) {
                              state.containers[containerId].subforms.containers[
                                subform.subformId
                              ].toModuleData.error = message;
                              state.containers[containerId].subforms.containers[
                                subform.subformId
                              ].toModuleData.loading = false;
                              return;
                            }

                            state.containers[containerId].subforms.containers[
                              subform.subformId
                            ].toModuleData.loading = false;

                            state.containers[containerId].subforms.containers[
                              subform.subformId
                            ].toModuleData.toModuleName = value.api_name;

                            //Get To Module Field's mandatory fields
                            const mandatoryFields = fields
                              .filter((field) => field.required)
                              .map((field) => ({
                                id: field.id,
                                to: {
                                  api_name: field.api_name,
                                  data_type: field.data_type,
                                  field_label: field.field_label,
                                },
                                from: null,
                                mandatory: true,
                              }));

                            let updatedFieldMapping =
                              state.containers[containerId].subforms.containers[
                                subform.subformId
                              ].fieldMapping.length > 0
                                ? state.containers[containerId].subforms
                                    .containers[subform.subformId].fieldMapping
                                : [
                                    ...state.containers[containerId].subforms
                                      .containers[subform.subformId]
                                      .fieldMapping,
                                    ...mandatoryFields,
                                  ];

                            state.containers[containerId].subforms.containers[
                              subform.subformId
                            ].fieldMapping = updatedFieldMapping;
                            state.containers[containerId].subforms.containers[
                              subform.subformId
                            ].toModuleData.toModuleFields = fields;
                          }}
                          disableClearable={false}
                          // disabled={!!fromModuleName && !!toModuleName}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select To Module"
                              error={
                                !!stateSnap.containers[containerId].subforms
                                  .containers[subform.subformId].toModuleData
                                  .error
                              }
                              helperText={
                                stateSnap.containers[containerId].subforms
                                  .containers[subform.subformId].toModuleData
                                  .error
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {stateSnap.containers[containerId].subforms
                                      .containers[subform.subformId]
                                      .toModuleData.loading ? (
                                      <CircularProgress
                                        color="secondary"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                          disabled={
                            subform.fromModuleData.fromModuleName &&
                            subform.toModuleData.toModuleName
                          }
                        />
                      </Grid>
                      <Grid item xs>
                        {/* Select From Module */}
                        <Autocomplete
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          // options={subformFields.to}
                          options={subformModules.from.filter(
                            (subformModule) => {
                              // is module is already found in subforms.containers[id].fromModuleData then deduct that module from options
                              const isSelectedForMapping = Object.values(
                                state.containers[containerId].subforms
                                  .containers
                              ).find(
                                (subformContainer) =>
                                  subformContainer.fromModuleData
                                    .fromModuleName === subformModule.api_name
                              );
                              if (isSelectedForMapping) {
                                return false;
                              } else {
                                return true;
                              }
                            }
                          )}
                          value={subformModules.from.find(
                            (fromModule) =>
                              fromModule.api_name ===
                              stateSnap.containers[containerId].subforms
                                .containers[subform.subformId].fromModuleData
                                .fromModuleName
                          )}
                          getOptionLabel={(option) => option.field_label}
                          onChange={async (event, value) => {
                            console.log({ value });

                            state.containers[containerId].subforms.containers[
                              subform.subformId
                            ].fromModuleData.error = null;

                            // Fetch module fields
                            (async () => {
                              state.containers[containerId].subforms.containers[
                                subform.subformId
                              ].fromModuleData.loading = true;

                              let { ok, message, fields } =
                                await getModuleFields(value.api_name);

                              state.containers[containerId].subforms.containers[
                                subform.subformId
                              ].fromModuleData.loading = false;

                              //check error
                              if (!ok) {
                                state.containers[
                                  containerId
                                ].subforms.containers[
                                  subform.subformId
                                ].fromModuleData.error = message;
                                state.containers[
                                  containerId
                                ].subforms.containers[
                                  subform.subformId
                                ].fromModuleData.loading = false;
                                return;
                              }

                              state.containers[containerId].subforms.containers[
                                subform.subformId
                              ].fromModuleData.fromModuleName = value.api_name;
                              // get all lookup modules from fromModuleFields
                              const lookupModules = fields.flatMap((field) =>
                                field.data_type === "lookup" ? [field] : []
                              );

                              // it contains all fields include lookupmodule fields (for fromModuleFields)
                              let allfields_include_lookupmodule_fields = [];

                              // fields from main module and push those fields to allfields_include_lookupmodule_fields
                              for (
                                let field = 0;
                                field < fields.length;
                                field++
                              ) {
                                allfields_include_lookupmodule_fields.push({
                                  ...fields[field],
                                  moduleName: value.api_name,
                                });
                              }

                              //grab all lookup module fields and push those fields to allfields_include_lookupmodule_fields
                              for (
                                let lookupModule = 0;
                                lookupModule < lookupModules.length;
                                lookupModule++
                              ) {
                                let getLookupFields = await getModuleFields(
                                  lookupModules[lookupModule].lookup.module
                                    .api_name
                                );

                                for (
                                  let lookupfield = 0;
                                  lookupfield < getLookupFields.length;
                                  lookupfield++
                                ) {
                                  allfields_include_lookupmodule_fields.push({
                                    ...getLookupFields[lookupfield],
                                    moduleName:
                                      lookupModules[lookupModule].field_label,
                                    lookupfield_api_name:
                                      lookupModules[lookupModule].api_name,
                                  });
                                }
                              }

                              state.containers[containerId].subforms.containers[
                                subform.subformId
                              ].fromModuleData.fields =
                                allfields_include_lookupmodule_fields;
                            })();
                          }}
                          disableClearable={false}
                          // disabled={!!fromModuleName && !!toModuleName}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Module"
                              error={
                                !!stateSnap.containers[containerId].subforms
                                  .containers[subform.subformId].fromModuleData
                                  .error
                              }
                              helperText={
                                stateSnap.containers[containerId].subforms
                                  .containers[subform.subformId].fromModuleData
                                  .error
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {state.containers[containerId].subforms
                                      .containers[subform.subformId]
                                      .fromModuleData.loading ? (
                                      <CircularProgress
                                        color="secondary"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                          disabled={
                            subform.fromModuleData.fromModuleName &&
                            subform.toModuleData.toModuleName
                          }
                        />
                      </Grid>
                      <Grid item sx={{ width: "28px" }}></Grid>
                    </Grid>
                    {/* Field Mapping */}
                    {stateSnap.containers[containerId].subforms.containers[
                      subform.subformId
                    ]?.toModuleData.toModuleName &&
                      stateSnap.containers[containerId].subforms.containers[
                        subform.subformId
                      ]?.fromModuleData.fromModuleName &&
                      stateSnap.containers[containerId].subforms.containers[
                        subform.subformId
                      ].fieldMapping.map((field, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            gap: "15px",
                          }}
                          mt={2}
                          key={index}
                        >
                          <Box sx={{ flex: 1 }}>
                            <SelectToModuleField
                              fields={stateSnap.containers[
                                containerId
                              ].subforms.containers[
                                subform.subformId
                              ].toModuleData.toModuleFields.filter((field) => {
                                const mappedFields =
                                  stateSnap.containers[containerId].subforms
                                    .containers[subform.subformId].fieldMapping;

                                const fieldExistInFieldMapping =
                                  mappedFields.find(
                                    (mappedField) =>
                                      mappedField?.to?.api_name ===
                                      field.api_name
                                  );
                                if (fieldExistInFieldMapping) {
                                  return false;
                                } else {
                                  return true;
                                }
                              })}
                              label="Select To Module Field"
                              fieldIndex={index}
                              fieldData={field}
                              setFieldMapping={(event, value) => {
                                // set text to from fields in fieldmapping

                                const updatedFieldMapping =
                                  stateSnap.containers[
                                    containerId
                                  ].subforms.containers[
                                    subform.subformId
                                  ].fieldMapping.map((mappedField) => {
                                    if (mappedField.id !== field.id) {
                                      return mappedField;
                                    } else {
                                      return {
                                        ...mappedField,
                                        to: {
                                          api_name: value?.api_name ?? "",
                                          data_type: value?.data_type ?? "",
                                          field_label: value?.field_label ?? "",
                                        },
                                      };
                                    }
                                  });

                                state.updateSubformFieldMapping({
                                  containerId,
                                  subformId: subform.subformId,
                                  fieldMapping: updatedFieldMapping,
                                });
                              }}
                              fieldMapping={
                                stateSnap.containers[containerId].subforms
                                  .containers[subform.subformId].fieldMapping
                              }
                            />
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <SelectFromModuleFields
                              fields={
                                field?.to?.data_type === "text"
                                  ? stateSnap.containers[containerId].subforms
                                      .containers[subform.subformId]
                                      .fromModuleData.fields
                                  : stateSnap.containers[
                                      containerId
                                    ].subforms.containers[
                                      subform.subformId
                                    ].fromModuleData.fields.filter(
                                      (moduleField) =>
                                        moduleField.data_type ===
                                        field?.to?.data_type
                                    )
                              }
                              fieldData={field}
                              fromModuleName={
                                stateSnap.containers[containerId].subforms
                                  .containers[subform.subformId].fromModuleData
                                  .fromModuleName
                              }
                              allowedTypes={
                                stateSnap.containers[containerId].subforms
                                  .containers[subform.subformId].fromModuleData
                                  .allowedTypes
                              }
                              setFieldMapping={(textareaValue) => {
                                // console.log({ textareaValue });
                                // return;
                                const updatedFieldMapping =
                                  stateSnap.containers[
                                    containerId
                                  ].subforms.containers[
                                    subform.subformId
                                  ].fieldMapping.map((mappedField) => {
                                    if (mappedField.id !== field.id) {
                                      return mappedField;
                                    } else {
                                      return {
                                        ...mappedField,
                                        from: textareaValue,
                                      };
                                    }
                                  });

                                state.updateSubformFieldMapping({
                                  containerId,
                                  subformId: subform.subformId,
                                  fieldMapping: updatedFieldMapping,
                                });
                              }}
                            />
                          </Box>
                          {!field.mandatory ? (
                            <Box>
                              <IconButton
                                aria-label="delete"
                                color="error"
                                size="small"
                                onClick={() => {
                                  const filteredFieldMapping =
                                    stateSnap.containers[
                                      containerId
                                    ].subforms.containers[
                                      subform.subformId
                                    ].fieldMapping.filter(
                                      (mappedField) =>
                                        mappedField.id !== field.id
                                    );

                                  state.updateSubformFieldMapping({
                                    containerId,
                                    subformId: subform.subformId,
                                    fieldMapping: filteredFieldMapping,
                                  });
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box sx={{ width: "28px" }} />
                          )}
                        </Box>
                      ))}
                    {/* Add Field */}
                    {stateSnap.containers[containerId].subforms.containers[
                      subform.subformId
                    ]?.toModuleData.toModuleName &&
                      stateSnap.containers[containerId].subforms.containers[
                        subform.subformId
                      ]?.fromModuleData.fromModuleName && (
                        <Box
                          mt={2}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            onClick={() => {
                              //add field to that specific subform

                              state.addSubformField({
                                containerId,
                                subformId: subform.subformId,
                              });
                            }}
                            sx={{ borderRadius: "25px" }}
                            variant="outlined"
                            size="small"
                          >
                            Add Field
                          </Button>
                        </Box>
                      )}
                  </Box>
                </Collapse>
              </Box>
              <Box ml={1} mt={1}>
                {/* Dialog For Delete Section */}
                <AppDialog
                  openDialog={dialogForSubformDelete}
                  handleCancel={() => {
                    setDialogForSubformDelete(false);
                  }}
                  handleOk={() => {
                    state.deleteSubform({
                      containerId,
                      subformId: subform.subformId,
                    });

                    setDialogForSubformDelete(false);
                  }}
                  dialogTitle="Do you want to delete this Subform?"
                />
                <Icon
                  color="error"
                  onClick={() => {
                    setDialogForSubformDelete(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <RemoveCircleOutlineIcon />
                </Icon>
              </Box>
            </Box>
          );
        }
      )}

      {Math.min(subformModules.to?.length, subformModules.from?.length) >
        Object.keys(state.containers[containerId].subforms.containers)
          .length && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "15px",
            justifyContent: "center",
          }}
          mt={2}
        >
          <Button
            onClick={() => state.addSubform({ containerId })}
            variant="contained"
            size="small"
            sx={{
              borderRadius: "25px",
              background: "#C266FF",
              "&:hover": { background: "#B444FF" },
            }}
          >
            Add Subform
          </Button>
        </Box>
      )}
    </>
  );
}
