// import React from "react";
// //Material Ui
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import Checkbox from "@mui/material/Checkbox";
// import Typography from "@mui/material/Typography";
// import { useSnapshot } from "valtio";

// import { state } from "./Store";

// export default function ContainerSettings({ containerId }) {
//   const stateSnap = useSnapshot(state);
//   return (
//     <Grid container spacing={2}>
//       <Grid item container spacing={1} alignItems="center">
//         <Grid item>
//           <Typography>Settings</Typography>
//         </Grid>
//         <Grid item xs>
//           <Box
//             sx={{
//               height: "1px",
//               background: "#C7C7C7",
//             }}
//           ></Box>
//         </Grid>
//       </Grid>
//       <Grid item container spacing={2}>
//         {/* Add Settings */}
//         {state.containers[containerId].toModuleFields.length > 0 &&
//           Object.values(stateSnap.containers[containerId].settings).map(
//             (setting) => (
//               <Grid item xs={6} md={4} lg={3}>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "flex-start",
//                   }}
//                 >
//                   <Checkbox
//                     size="small"
//                     defaultChecked={setting.checked}
//                     onChange={(e) => {
//                       state.updateContainerSetting({
//                         containerId,
//                         settingId: setting.id,
//                         checkedStatus: e.target.checked,
//                       });
//                     }}
//                   />

//                   <Box>
//                     <Typography
//                       sx={{
//                         fontSize: "14px",
//                         fontWeight: 600,
//                       }}
//                     >
//                       {setting.label}
//                     </Typography>
//                     <Typography
//                       sx={{
//                         fontSize: "14px",
//                         color: "#898989",
//                       }}
//                     >
//                       {setting.additionalInfo}
//                     </Typography>
//                   </Box>
//                 </Box>
//               </Grid>
//             )
//           )}
//       </Grid>
//       <Grid item container spacing={1} alignItems="center">
//         <Grid item xs>
//           <Box
//             sx={{
//               height: "1px",
//               background: "#C7C7C7",
//             }}
//           ></Box>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// }

import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSnapshot } from "valtio";

import { state } from "./Store";

export default function ContainerSettings({ containerId }) {
  const stateSnap = useSnapshot(state);
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={2}
      id="multiple-limit-tags"
      size="small"
      options={settings}
      getOptionLabel={(option) => option.label}
      value={stateSnap.containers[containerId].settings.map((selectedSetting) =>
        settings.find((setting) => setting.id === selectedSetting.id)
      )}
      renderInput={(params) => (
        <TextField
          InputLabelProps={{ shrink: true, notched: true }}
          {...params}
          label="Settings"
        />
      )}
      onChange={(event, value, reason) => {
        state.updateContainerSetting({
          containerId,
          settings: value,
        });
      }}
    />
  );
}

const settings = [
  {
    label: "Automations",
    additionalInfo: "Trigger Workflow, Approval, Blueprint",
    id: "Workflow_Approval_Blueprint",
  },
  {
    label: "Events",
    additionalInfo: "Events will be Copied to New Record",
    id: "Events",
  },
  {
    label: "Calls",
    additionalInfo: "Calls will be Copied to New Record",
    id: "Calls",
  },
  {
    label: "Tasks",
    additionalInfo: "Tasks will be Copied to New Record",
    id: "Tasks",
  },
  {
    label: "Attachments",
    additionalInfo: "Attachments will be Created to New Record",
    id: "Attachments",
  },
  {
    label: "Notes",
    additionalInfo: "Notes will be created to New Record",
    id: "Notes",
  },
  {
    label: "PrimaryModule",
    additionalInfo: "Events/Calls/Tasks will moved to New Record",
    id: "PrimaryModule",
  },
];
