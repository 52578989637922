const getAllModules = async () => {
  try {
    const ZOHO = await window.ZOHO;
    const { modules } = await ZOHO.CRM.META.getModules();
    const filteredModules = modules.filter(
      (module) =>
        !module?.web_link &&
        // module?.show_as_tab &&
        // module?.creatable &&
        // module?.visible &&
        module?.triggers_supported
    );

    return filteredModules;
  } catch (err) {
    return err;
  }
};
export default getAllModules;
