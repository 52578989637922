//Material Ui
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

export default function SelectToModuleField({
  fields,
  label,
  fieldIndex,
  fieldData,
  setFieldMapping,
  fieldMapping,
}) {
  return (
    <Autocomplete
      size="small"
      disablePortal
      id="combo-box-demo"
      options={fields}
      value={fieldData.to}
      getOptionLabel={(option) => option.field_label}
      disabled={fieldData?.["mandatory"]}
      disableClearable={false}
      onChange={setFieldMapping}
      renderInput={(params) => <TextField {...params} placeholder={label} />}
    />
  );
}
