import { Box, Button, Divider, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { useEffect, useState } from "react";
import Overview from "./Overview";
// import SettingsPage from "../SettingsPage/SettingsPage";
import axios from "axios";
const ZOHO = window.ZOHO;

export default function Authentication() {
  const [auth, setAuth] = useState("Active");
  const [settings, setSettings] = useState();
  const [apiKey, setApiKey] = useState();
  const [orgId, setOrgId] = useState();
  const [zapiKey, setZapiKey] = useState();
  const [dataCenterUrl, setDataCenterUrl] = useState();
  const [zuid, setZuid] = useState(null);

  const [zohoLoaded, setZohoLoaded] = useState(false);

  useEffect(() => {
    async function initZoho() {
      ZOHO.embeddedApp.on("PageLoad", async function (data) {
        console.log({ zohoInitialData: data });
        setZohoLoaded(true);
      });

      ZOHO.embeddedApp.init();
    }
    initZoho();
  }, []);

  const handleChange = () => {
    setAuth("Successful");
    setSettings("Active");
  };

  const handleAuthenticate = async () => {
    console.log({
      dataCenterUrl,
      apiKey,
      orgId,
      zapiKey,
      env: process.env.REACT_APP_ADMIN_API_URL,
    });
    const crmApiDomain =
      "https://www.zohoapis." +
      dataCenterUrl?.split("https://accounts.zoho.")?.[1];
    const authUrlResp = await axios.request({
      url: process.env.REACT_APP_ADMIN_API_URL + "/auth/zoho/authenticate",
      method: "POST",
      data: {
        orgId: orgId,
        apiKey: apiKey,
        zapiKey: zapiKey,
        accountsUrl: dataCenterUrl,
        connName: "fieldmappingforcrm",
        scope: [
          "ZohoCRM.settings.ALL",
          "ZohoCRM.org.ALL",
          "ZohoCRM.coql.READ",
          "ZohoCRM.users.ALL",
          "ZohoCRM.modules.ALL",
          "ZohoCRM.bulk.ALL",
          "ZohoCRM.notifications.ALL",
        ],
        redirectUrl:
          process.env.REACT_APP_ADMIN_API_URL + "/auth/zoho/callback",
        crmApiDomain: crmApiDomain,
        zuid: zuid,
      },
    });
    console.log(
      "🚀 ~ file: Authentication.jsx ~ line 106 ~ handleAuthenticate ~ authUrlResp",
      authUrlResp,
      process.env.REACT_APP_ADMIN_API_URL + "/v2/auth/zoho/callback"
    );

    const newwindow = window.open(
      authUrlResp?.data?.authUrl,
      "windowName",
      "height=640,width=1024"
    );
    if (window.focus) {
      newwindow.focus();
    }
  };

  const fetchOrgVariablesData = async () => {
    /**
     * Get Organization Variables
     */
    const orgData = {
      apiKeys: [
        "zfieldmappingforcrm__API_KEY",
        "zfieldmappingforcrm__Organization_ID",
        "zfieldmappingforcrm__ZAPI_Key",
        "zfieldmappingforcrm__ZUID",
        "zfieldmappingforcrm__Data_Center_URL",
      ],
    };
    const orgVariables = await ZOHO.CRM.API.getOrgVariable(orgData);
    console.log(
      "🚀 ~ file: Authentication.jsx ~ line 128 ~ fetchOrgVariablesData ~ orgVariables",
      orgVariables
    );
    setApiKey(
      orgVariables?.Success?.Content?.zfieldmappingforcrm__API_KEY?.value
    );
    setOrgId(
      orgVariables?.Success?.Content?.zfieldmappingforcrm__Organization_ID
        ?.value
    );
    setZapiKey(
      orgVariables?.Success?.Content?.zfieldmappingforcrm__ZAPI_Key?.value
    );
    /**
     * Fetch User Details
     */
    const orgResp = await ZOHO.CRM.CONFIG.getOrgInfo();
    setZuid(orgResp?.org?.[0]?.primary_zuid);
    console.log(
      "🚀 ~ file: Authentication.jsx ~ line 128 ~ fetchOrgVariablesData ~ orgResp",
      orgResp?.org?.[0]?.primary_zuid
    );
  };

  useEffect(() => {
    if (zohoLoaded) {
      fetchOrgVariablesData();
    }
  }, [zohoLoaded]);

  if (!zohoLoaded) {
    return <>Not loaded yet</>;
  }

  return (
    <Box>
      <Box
        sx={{
          p: 2.5,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            ml: 9,
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#1F6DDE",
          }}
        >
          Welcome to ZOHO Extension Test Platform
        </Typography>
        {/* <Box sx={{ mt: 1, mr: 2, display: "flex" }}>
          <PlayCircleOutlineIcon sx={{ color: "#1F6DDE", mr: 1 }} />
          <a
            href="www.google.com"
            style={{
              textDecoration: "none",
              color: "#1F6DDE",
              fontWeight: "500",
            }}
          >
            {" "}
            View Tutorial
          </a>
        </Box> */}
      </Box>
      <Divider />

      <Box
        sx={{
          mt: 4,
          mb: 5,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {auth === "Active" ? (
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: "9px",
              mr: 2,
              py: 4,
              fontWeight: "600",
              height: "3rem",
              color: "#000000",
              bgcolor: "#F6FAFD",
              width: "14rem",
            }}
          >
            {" "}
            <Box
              sx={{
                bgcolor: "#1F6DDE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 0.4,
                borderRadius: "50%",
                height: "35px",
                width: "35px",
                mr: 1,
              }}
            >
              <ViewModuleIcon sx={{ color: "white", fontSize: 19 }} />
            </Box>{" "}
            Authentication
          </Button>
        ) : (
          <Button
            onClick={() => {
              setAuth("Active");
              setSettings("Inactive");
            }}
            variant="outlined"
            sx={{
              textTransform: "none",
              border: "none",
              borderRadius: "9px",
              mr: 2,
              py: 4,
              fontWeight: "600",
              height: "3rem",
              color: "#000000",
              bgcolor: "rgba(0, 0, 0, 0.04)",
              width: "14rem",
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.06) !important",
                border: "none",
              },
            }}
          >
            {" "}
            <Box
              sx={{
                bgcolor: "#2E7D32",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 0.4,
                borderRadius: "50%",
                height: "35px",
                width: "35px",
                mr: 1,
              }}
            >
              <ViewModuleIcon sx={{ color: "white", fontSize: 19 }} />
            </Box>{" "}
            Authenticated
          </Button>
        )}
        {/* {settings === "Active" ? (
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: "9px",
              mr: 2,
              py: 4,
              fontWeight: "600",
              height: "3rem",
              color: "#000000",
              bgcolor: "#F6FAFD",
              width: "14rem",
            }}
          >
            {" "}
            <Box
              sx={{
                bgcolor: "#1F6DDE",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 0.4,
                borderRadius: "50%",
                height: "35px",
                width: "35px",
                mr: 1,
              }}
            >
              <ViewModuleIcon sx={{ color: "white", fontSize: 19 }} />
            </Box>{" "}
            Settings
          </Button>
        ) : (
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              borderRadius: "9px",
              p: 3,
              fontWeight: "600",
              height: "4rem",
              border: "none",
              width: "14rem",
              color: "#000000",
              bgcolor: "rgba(0, 0, 0, 0.06)",
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.06) !important",
                border: "none",
              },
            }}
          >
            <Box
              sx={{
                bgcolor: "rgba(0, 0, 0, 0.38)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 0.4,
                borderRadius: "50%",
                height: "35px",
                width: "35px",
                mr: 1,
              }}
            >
              <ViewModuleIcon sx={{ color: "white", fontSize: 19 }} />
            </Box>
            Settings
          </Button>
        )} */}
      </Box>
      {auth === "Active" && (
        <Overview
          handleChange={handleChange}
          setDataCenterUrl={setDataCenterUrl}
          handleAuthenticate={handleAuthenticate}
        />
      )}
    </Box>
  );
}
