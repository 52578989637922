import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import { state } from "./Store";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AppDialog from "./Organisms/AppDialog";

export default function DeleteContainer({ containerId, setLoadingForEditing }) {
  const [dialogForContainerDelete, setDialogForContainerDelete] =
    useState(false);
  const [deleteContainerId, setDeleteContainerId] = useState(null);
  return (
    <>
      {/* Dialog For Delete Container */}
      <AppDialog
        openDialog={dialogForContainerDelete}
        handleCancel={() => {
          setDialogForContainerDelete(false);
          setDeleteContainerId(null);
        }}
        handleOk={() => {
          //delete container
          state.deleteContainer(deleteContainerId);
          setDialogForContainerDelete(false);
          setDeleteContainerId(null);
          setLoadingForEditing(false);
        }}
        dialogTitle="Do you want to delete this section?"
      />
      <Icon
        color="error"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          setDialogForContainerDelete(true);
          setDeleteContainerId(containerId);
        }}
      >
        <RemoveCircleOutlineIcon color="red" />
      </Icon>
    </>
  );
}
