import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Box, Button, TextField } from "@mui/material";
import { useSnapshot } from "valtio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";

import AppDialog from "./Organisms/AppDialog";
import SelectModule from "./SelectModule";
import { state } from "./Store";
import getLayouts from "./getLayouts";
import getModuleFields from "./getModuleFields";

export default function DialogForFromModule({
  modules,
  handleFromModuleSubmit,
}) {
  const [selectedAction, setSelectedAction] = useState(null);
  const stateSnap = useSnapshot(state);
  console.log(
    "🚀 ~ file: DialogForFromModule.js ~ line 26 ~ stateSnap",
    stateSnap
  );

  // stateSnap.fromModuleData.fromModuleName = "Contacts";
  useEffect(() => {
    const moduleName = stateSnap.fromModuleData.fromModuleName;
    // if (!moduleName) {
    //   state.fromModuleData.error = null;
    //   state.fromModuleData.fromModuleName = null;
    //   return;
    // }
    state.fromModuleData.selectedLayout = null;
    state.fromModuleData.fields = [];
    state.fromModuleData.layoutData = {};
    state.fromModuleData.error = null;
    // state.fromModuleData.fromModuleName = moduleName;
    state.fromModuleData.loading = true;
    //Fetch module fields
    (async () => {
      //get layout info
      let {
        selectedLayout,
        allLayouts,
        status,
        errorMsg,
        subformModulesOfLayouts,
      } = await getLayouts(moduleName);

      state.fromModuleData.loading = false;

      //check error
      if (status === "error") {
        state.fromModuleData.error = errorMsg;
        return;
      }

      //update fromModuleData
      state.updateFromModuleData({
        layoutData: allLayouts,
        subformModulesOfLayouts,
        fromModuleName: moduleName,
      });

      return;
    })();
  }, []);

  useEffect(() => {
    /* if there is only one layoutData set this as selected layout */

    Object.keys(stateSnap.fromModuleData.layoutData).length === 1 &&
      (async () => {
        const layout = Object.values(stateSnap.fromModuleData.layoutData)[0];

        console.log({ layout });
        const fields = stateSnap.fromModuleData.layoutData[layout.id].fields;

        //TODO fetch ownerlookup module from users module

        // get all lookup modules from fromModuleFields
        const lookupModules = fields.flatMap((field) =>
          field.data_type === "lookup" ? [field] : []
        );
        console.log({ lookupModules });

        // it contains all fields include lookupmodule fields (for fromModuleFields)
        let allfields_include_lookupmodule_fields = [];

        // fields from main module and push those fields to allfields_include_lookupmodule_fields
        for (let field = 0; field < fields.length; field++) {
          allfields_include_lookupmodule_fields.push({
            ...fields[field],
            moduleName: stateSnap.fromModuleData.fromModuleName,
          });
        }

        console.log({ allfields_include_lookupmodule_fields });

        //grab all lookup module fields and push those fields to allfields_include_lookupmodule_fields
        for (
          let lookupModule = 0;
          lookupModule < lookupModules.length;
          lookupModule++
        ) {
          let getLookupFields = await getModuleFields(
            lookupModules[lookupModule].lookup.module.api_name
          );

          getLookupFields.fields.forEach((lookupField) => {
            allfields_include_lookupmodule_fields.push({
              ...lookupField,
              moduleName: lookupModules[lookupModule].field_label,
              lookupfield_api_name:
                stateSnap.fromModuleData.fromModuleName +
                "." +
                lookupModules[lookupModule].api_name,
            });
          });
        }

        state.fromModuleData.fields = [
          ...allfields_include_lookupmodule_fields,
          {
            json_type: "string",
            field_label: "Current Date",
            required: false,
            display_label: "Current Date",
            id: "47311122081018",
            api_name: "Current_Execution__Date",
            data_type: "date",
            moduleName: "Custom",
          },
          {
            json_type: "string",
            field_label: "Current Date Time",
            required: false,
            display_label: "Current Date Time",
            id: "4411111122081019",
            api_name: "Current_Execution__Date_Time",
            data_type: "datetime",
            moduleName: "Custom",
          },
          {
            json_type: "jsonobject",
            field_label: "Logged-In User",
            required: false,
            display_label: "Logged-In User",
            id: "1111122081019",
            api_name: "Logged_In_User",
            data_type: "ownerlookup",
            moduleName: "Custom",
          },
        ];

        state.fromModuleData.subformModules =
          stateSnap.fromModuleData.subformModulesOfLayouts[layout.id] || [];
        state.fromModuleData.selectedLayout = {
          id: layout.id,
          name: layout.field_label,
        };
      })();
  }, [stateSnap.fromModuleData.layoutData]);

  return (
    <AppDialog
      openDialog={true}
      showDialogActions={false}
      sx={{ minWidth: "500px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <FormControl>
          <h3>What you want to do?</h3>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="row-radio-buttons-group"
            value={selectedAction}
            onChange={(e) => setSelectedAction(e.target.value)}
          >
            <FormControlLabel
              value="create"
              control={<Radio />}
              label="Create Record"
            />
            <FormControlLabel
              value="update"
              control={<Radio />}
              label="Update Record"
            />
          </RadioGroup>
        </FormControl>
        {Object.keys(stateSnap.fromModuleData.layoutData).length > 1 && (
          <FormControl>
            <h3>Select Layout</h3>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={async (event, value) => {
                const layout = JSON.parse(event.target.value);

                console.log({ layout });
                const fields =
                  stateSnap.fromModuleData.layoutData[layout.id].fields;

                //TODO fetch ownerlookup module from users module

                // get all lookup modules from fromModuleFields
                const lookupModules = fields.flatMap((field) =>
                  field.data_type === "lookup" ? [field] : []
                );
                console.log({ lookupModules });

                // it contains all fields include lookupmodule fields (for fromModuleFields)
                let allfields_include_lookupmodule_fields = [];

                // fields from main module and push those fields to allfields_include_lookupmodule_fields
                for (let field = 0; field < fields.length; field++) {
                  allfields_include_lookupmodule_fields.push({
                    ...fields[field],
                    moduleName: stateSnap.fromModuleData.fromModuleName,
                  });
                }

                //grab all lookup module fields and push those fields to allfields_include_lookupmodule_fields
                for (
                  let lookupModule = 0;
                  lookupModule < lookupModules.length;
                  lookupModule++
                ) {
                  let getLookupFields = await getModuleFields(
                    lookupModules[lookupModule].lookup.module.api_name
                  );

                  getLookupFields.fields.forEach((lookupField) => {
                    allfields_include_lookupmodule_fields.push({
                      ...lookupField,
                      moduleName: lookupModules[lookupModule].field_label,
                      lookupfield_api_name:
                        stateSnap.fromModuleData.fromModuleName +
                        "." +
                        lookupModules[lookupModule].api_name,
                    });
                  });
                }

                state.fromModuleData.fields = [
                  ...allfields_include_lookupmodule_fields,
                  {
                    json_type: "string",
                    field_label: "Current Date",
                    required: false,
                    display_label: "Current Date",
                    id: "47311122081018",
                    api_name: "Current_Execution__Date",
                    data_type: "date",
                    moduleName: "Custom",
                  },
                  {
                    json_type: "string",
                    field_label: "Current Date Time",
                    required: false,
                    display_label: "Current Date Time",
                    id: "4411111122081019",
                    api_name: "Current_Execution__Date_Time",
                    data_type: "datetime",
                    moduleName: "Custom",
                  },
                  {
                    json_type: "jsonobject",
                    field_label: "Logged-In User",
                    required: false,
                    display_label: "Logged-In User",
                    id: "1111122081019",
                    api_name: "Logged_In_User",
                    data_type: "ownerlookup",
                    moduleName: "Custom",
                  },
                ];

                state.fromModuleData.subformModules =
                  stateSnap.fromModuleData.subformModulesOfLayouts[layout.id] ||
                  [];
                state.fromModuleData.selectedLayout = {
                  id: layout.id,
                  name: layout.field_label,
                };
              }}
            >
              {Object.values(stateSnap.fromModuleData.layoutData).map(
                (layout) => (
                  <FormControlLabel
                    value={JSON.stringify(layout)}
                    control={<Radio />}
                    label={layout.field_label}
                  />
                )
              )}
            </RadioGroup>
          </FormControl>
        )}

        <Box mt={1.5} p={0}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={stateSnap.fromModuleData.deleteRecord}
                size="small"
                sx={{ padding: 0, margin: 0, mr: 1 }}
                onChange={(e) =>
                  (state.fromModuleData.deleteRecord = e.target.checked)
                }
              />
            }
            sx={{ margin: 0, padding: 0 }}
            label="Do you want to delete record after completing fieldmapping?"
          />
        </Box>
        <Button
          variant="contained"
          size="small"
          onClick={handleFromModuleSubmit}
          sx={{ mt: 1.5, mr: 1 }}
          disabled={
            selectedAction && stateSnap.fromModuleData.selectedLayout
              ? false
              : true
          }
        >
          Save
        </Button>
      </Box>
    </AppDialog>
  );
}
