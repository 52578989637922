import React from "react";
//Material Ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSnapshot } from "valtio";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import { state } from "./Store";
import SelectModule from "./SelectModule";
import getLayouts from "./getLayouts";
import ContainerSettings from "./ContainerSettings";

export default function ContainerToModuleAndLayout({ modules, containerId }) {
  const stateSnap = useSnapshot(state);
  return (
    <Grid container spacing={1.5}>
      <Grid item xs={12} sm={4} spacing={1} alignItems="center">
        {/* Select toModule */}
        <SelectModule
          modules={modules}
          moduleName={stateSnap.containers[containerId].toModuleName}
          error={stateSnap.containers[containerId].error}
          loading={stateSnap.containers[containerId].loading}
          setModuleName={async (moduleName) => {
            if (!moduleName) {
              state.containers[containerId].error = null;
              state.containers[containerId].toModuleName = null;

              return;
            }
            state.containers[containerId].error = null;
            state.containers[containerId].toModuleName = moduleName;
            state.containers[containerId].loading = true;

            //get layout info
            let {
              selectedLayout,
              allLayouts,
              status,
              errorMsg,
              subformModulesOfLayouts,
            } = await getLayouts(moduleName);
            if (status === "error") {
              // alert(message);
              state.containers[containerId].error = errorMsg;
              return;
            }
            state.containers[containerId].loading = false;
            console.log(
              "Modulessss",
              subformModulesOfLayouts[selectedLayout.id]
            );

            //set Setting Name
            const settingName =
              stateSnap.containers[containerId].settingName.split("_");
            const updatedSettingName =
              settingName[0] + "_" + moduleName + "_" + settingName[1];

            //if both module has subform fields then we can add subform
            let shouldSubformAdd = false;
            let subformModules = {
              to: [],
              from: [],
            };
            const subformOfToModule =
              subformModulesOfLayouts[selectedLayout.id];
            if (
              subformOfToModule?.length > 0 &&
              stateSnap.fromModuleData.subformModules.length > 0
            ) {
              subformModules = {
                to: subformOfToModule,
                from: stateSnap.fromModuleData.subformModules,
              };
              shouldSubformAdd = true;
            }

            // update container
            state.updateContainerData({
              containerId,
              subformModules,
              settingName: updatedSettingName,
              subformModulesOfLayouts,
              shouldSubformAdd,
              layoutData: allLayouts,
              selectedLayout,
              fieldMapping: selectedLayout.mandatoryFields,
              toModuleFields: selectedLayout.fields,
            });
          }}
          label="Select To Module"
          forFromModule={true}
          disabled={
            stateSnap.containers[containerId].toModuleName &&
            !stateSnap.containers[containerId].error
          }
        />
      </Grid>
      {stateSnap.containers[containerId].selectedLayout && (
        <>
          <Grid item xs={12} sm={4} spacing={1} alignItems="center">
            {/* Select Layout */}
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Layout</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={stateSnap.containers[containerId].selectedLayout.id}
                label="Layout"
                onChange={(event) => {
                  const layoutId = event.target.value;
                  //update layout
                  console.log({
                    layoutId,
                    containerId,
                  });

                  new Promise(function (resolve, reject) {
                    state.containers[containerId].fieldMapping = [];
                    state.containers[containerId].subforms.containers = {};
                    resolve();
                  }).then(() => {
                    state.updateContainerLayout({
                      containerId,
                      layoutId,
                    });
                  });
                }}
              >
                {Object.values(
                  stateSnap.containers[containerId].layoutData
                ).map((layout) => (
                  <MenuItem value={layout.id}>{layout.field_label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {!(
            stateSnap.containers[containerId].toModuleName === "Tasks" ||
            stateSnap.containers[containerId].toModuleName === "Calls" ||
            stateSnap.containers[containerId].toModuleName === "Events"
          ) && (
            <Grid item xs={12} sm={4} spacing={1} alignItems="center">
              <ContainerSettings containerId={containerId} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
