import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function Overview({
  handleChange,
  setDataCenterUrl,
  handleAuthenticate,
}) {
  const dataCenterUrlOptions = [
    "https://accounts.zoho.com",
    "https://accounts.zoho.com.au",
    "https://accounts.zoho.com.au",
    "https://accounts.zoho.in",
    "https://accounts.zoho.com.cn",
    "https://accounts.zoho.jp",
  ];
  const [show, setShow] = useState(false);
  return (
    <Box
      sx={{
        py: 2,
        width: "85%",
        mx: "auto",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        mb: 3,
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography sx={{ mt: 2, mb: 1, fontSize: "2rem", fontWeight: "bold" }}>
          Overview
        </Typography>
        <Divider />
        <Typography
          sx={{
            my: 2,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          Mobile stepper
        </Typography>
        <Typography
          sx={{
            mt: 2,
            mb: 3.5,
            color: "rgba(0, 0, 0, 0.6)",
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          Dis mauris elit molestie diam vivamus fames. Facilisi morbi
          consectetur nunc turpis accumsan tortor elementum aliquam fusce.
          Convallis mi odio nunc imperdiet nibh donec. Amet nunc gravida tempor
          enim at netus. Curabitur in ultricies auctor euismod cursus lacus
          tincidunt egestas. Ut nisi volutpat fringilla amet suscipit at proin.
          Et cursus dignissim dolor sodales eu risus. Gravida blandit nibh magna
          eu urna euismod morbi integer tincidunt. Ipsum cursus velit viverra
          leo accumsan nunc tincidunt morbi.
        </Typography>
        <Typography
          sx={{
            my: 2,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          Mobile stepper
        </Typography>
        <Typography
          sx={{
            my: 2,
            color: "rgba(0, 0, 0, 0.6)",
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          Aliquam ultricies nisl scelerisque mauris tempus nec dictumst purus
          arcu. Imperdiet tortor suspendisse gravida condimentum. Venenatis
          aliquam at porttitor a. Nisl hendrerit velit integer amet magnis.
          Facilisis accumsan dolor porttitor enim massa tempus ut sit purus.
          Egestas consequat turpis amet risus. Nibh rhoncus consectetur sapien
          mi senectus vel ut. Ullamcorper cras non imperdiet lectus nibh
          maecenas mi.
        </Typography>
      </Box>
      <Box sx={{ width: "30%", mx: "auto", mt: 2 }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={dataCenterUrlOptions}
          onChange={(e, v) => setDataCenterUrl(v)}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Data Center Url"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: show ? "flex-end" : "center",
          mr: show ? 2 : 0,
        }}
      >
        {show ? (
          <Button
            onClick={handleChange}
            sx={{
              mt: 2.5,
              mb: 2,
              width: "8rem",
              borderRadius: "8px",
              height: "2.9rem",
              textTransform: "none",
              bgcolor: "#1850A0",
            }}
            variant="contained"
          >
            Next
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleAuthenticate();
              // setShow(true);
            }}
            sx={{
              mt: 2.5,
              mb: 2,
              textTransform: "none",
              width: "11rem",
              borderRadius: "8px",
              height: "2.9rem",
              bgcolor: "#1850A0",
            }}
            variant="contained"
          >
            Authentication
          </Button>
        )}
      </Box>
    </Box>
  );
}
