import { v4 as uuidv4 } from "uuid";

const getLayouts = async (moduleName) => {
  //if there is no moduleName found in the argument then throw error
  if (!moduleName) {
    return new Error("Module Name is required.");
  }
  const ZOHO = await window.ZOHO;
  try {
    const response = await ZOHO.CRM.META.getLayouts({
      Entity: moduleName,
    });
    console.log("Response", { response });

    //check error
    if (response.status === "error") {
      let errorMsg = "";

      if (response.status === "error") {
        errorMsg += `${response.message} (${moduleName}) `;
      }

      return { status: "error", errorMsg: errorMsg };
    }

    //here we store all layouts info of that module
    const allLayouts = {};
    const subformModulesOfLayouts = {};

    response.layouts.forEach((layout) => {
      //Assign variables for mandatory fields and fields of the layout
      let fields = [];
      let mandatoryFields = [];
      subformModulesOfLayouts[layout.id] = [];

      layout.sections.forEach((section) => {
        //layout fields
        fields = [...fields, ...section.fields];

        //for subform module of the layout
        if (section.isSubformSection && section.fields.length > 0) {
          subformModulesOfLayouts[layout.id].push({
            field_label: section.display_label,
            id: uuidv4(),
            fields: section.fields,
            api_name: section.fields[0].subform.module,
          });
        }
      });
      console.log(
        "🚀 ~ file: getLayouts.js ~ line 39 ~ layout.sections.forEach ~ fields",
        fields
      );

      /**
       * Filtering Fields
       */
      //BUG
      const formatedFields = fields.filter(
        (field) =>
          !field.read_only &&
          field.data_type !== "profileimage" &&
          field.view_type.create
      );

      fields = [...formatedFields];
      //mandatory fields for the layout
      fields.forEach((field) => {
        if (field.required) {
          mandatoryFields.push({
            id: field.id,
            to: {
              api_name: field.api_name,
              data_type: field.data_type,
              field_label: field.field_label,
              ...(field.data_type === "picklist" && {
                pick_list_values: field.pick_list_values,
              }),
              ...(field.data_type === "lookup" && {
                lookup: field.lookup,
              }),
            },
            from: null,
            mandatory: true,
          });
        }
      });

      //for mandatory field of subform module
      for (let layout in subformModulesOfLayouts) {
        subformModulesOfLayouts[layout].forEach((module, index) => {
          const requiredFields = [];
          module.fields.forEach((field) => {
            if (field.required) requiredFields.push(field);
          });
          subformModulesOfLayouts[layout][index].mandatoryFields =
            requiredFields;
        });
      }

      console.log("Line 39", {
        fields,
        mandatoryFields,
        subformModulesOfLayouts,
      });

      //set field_label,id,fields and mandatoryFields of the layout to allLayouts
      allLayouts[layout.id] = {
        field_label: layout.display_label,
        id: layout.id,
        fields,
        mandatoryFields,
      };
    });

    const selectedLayout =
      Object.values(allLayouts).find(
        (layout) => layout.field_label === "Standard"
      ) || allLayouts[0];

    console.log({ allLayouts, selectedLayout });
    return {
      selectedLayout,
      allLayouts,
      subformModulesOfLayouts,
    };
  } catch (err) {
    return { status: "error", errorMsg: err.message };
  }
};
export default getLayouts;
